import React, { createContext, useContext, useEffect, useState } from "react";
import { JcPage } from "../../../JcSDK/JcPage";
import { JcAppTitleBar } from "../../JcAppTitleBar";
import { NFLBetsView } from "./NFLBetsView";
import { GamesAuthWrapper } from "../GamesAuthWrapper";
import { NFLTeamInfo } from "./models/NFLTeamInfo";
import { JcFullPageLoadingIndicator } from "../../../JcSDK/JcLoadingIndicator";




const NFLTeamInfosContext = createContext<NFLTeamInfo[] | null>(null);

export const useAccount = () => {
    const nflTeamInfosContext = useContext(NFLTeamInfosContext);

    if (!nflTeamInfosContext) {
        throw new Error("useAccount has to be used within <NFLTeamInfosContext.Provider>");
    }

    return nflTeamInfosContext;
};


export const NFLBetsPage = (): React.ReactElement => {

    const [teamInfos, setTeamInfos] = useState<NFLTeamInfo[] | null>(null);

    useEffect(() => {
        NFLTeamInfo.getTeamInfos().then(setTeamInfos);
    }, []);

    return <JcPage className="nfl-bets-page jc-page-with-title-bar">
        <JcAppTitleBar location="BETS" />
        <GamesAuthWrapper>
            {teamInfos
                ? <NFLBetsView teamInfos={teamInfos} />
                : <JcFullPageLoadingIndicator />}
        </GamesAuthWrapper>
    </JcPage>;
}