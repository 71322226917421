import React from 'react';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';


export interface JcBoxProps extends JcCommonProps {
  spacing?: boolean;
  alignCenter?: boolean;
  justifyCenter?: boolean;
  flexGrow?: boolean;
  children: React.ReactNode;
}

export const JcBox: React.FunctionComponent<JcBoxProps> = (props: JcBoxProps) => {

  const commonProps = JcUtils.commonProps(props, [
    props.spacing && "jc-spacing",
    props.alignCenter && "jc-align-center",
    props.justifyCenter && "jc-justify-center",
    props.flexGrow && "jc-flex-grow",
    "jc-box",
  ]);

  return <div {...commonProps}>
    {props.children}
  </div>
}

export const JcRow: React.FunctionComponent<JcBoxProps> = (props: JcBoxProps) => {

  const commonProps = JcUtils.commonProps(props, ["jc-row"]);
  const boxProps = JcUtils.otherProps(props);

  return <JcBox {...boxProps} {...commonProps} />
}

export const JcColumn: React.FunctionComponent<JcBoxProps> = (props: JcBoxProps) => {

  const commonProps = JcUtils.commonProps(props, ["jc-column"]);
  const boxProps = JcUtils.otherProps(props);

  return <JcBox {...boxProps} {...commonProps} />
}