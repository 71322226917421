import React, { useEffect, useState } from 'react';
import { JcRow, JcColumn } from '../../../JcSDK/JcBox';
import { NFLBetter } from './models/NFLBetter';
import { NFLBet } from './models/NFLBet';
import { JcSelectionBar } from '../../../JcSDK/button/JcButtonBar';
import { NFLBetsGraph } from './NFLBetsGraph';
import { JcDialogButton } from '../../../JcSDK/button/JcDialogButton';
import { JcIcon } from '../../../JcSDK/JcIcon';
import { NFLTeamInfo } from './models/NFLTeamInfo';


interface NFLBetsPickerProps {
  currentUser: NFLBetter;
  teamInfos: NFLTeamInfo[];
}


export const NFLBetsPicker: React.FunctionComponent<NFLBetsPickerProps> = (props: NFLBetsPickerProps) => {

  const [bets, setBets] = useState<NFLBet[]>([]);

  const [isInfoOpen, setInfoOpen] = useState(false);

  useEffect(() => {
    if (bets.length === 0) {
      setInfoOpen(true);
    }
  }, [bets.length])


  const readyToSubmit = bets.length === props.teamInfos.length;

  return <JcColumn flexGrow spacing>
    <JcRow spacing>
      <JcSelectionBar
        className="nfl-bets-submit-bar"
        options={[
          {
            id: "SUBMIT",
            label: "Submit",
            disabled: !readyToSubmit,
            onClick: () => props.currentUser.setBets(bets),
          }, {
            id: "RESET",
            label: "Reset",
            disabled: bets.length === 0,
            onClick: () => setBets([]),
          }
        ]} />
      <JcDialogButton
        trigger="jc:info"
        title={<JcRow alignCenter spacing><JcIcon icon="jc:info" />{"Info"}</JcRow>}
        setOpen={setInfoOpen}
        open={isInfoOpen}
        popupContent={<div className="nfl-info-message">Looks like you haven't placed any bets. Click above the line to select the over, and under the line to select the under. When you are finished, select 'Submit'.</div>} />
    </JcRow>
    <NFLBetsGraph
      teamInfos={props.teamInfos}
      bets={bets}
      showSelection
      onClick={(teamInfo, type) => {
        const bet = bets.find(bet => bet.teamID === teamInfo.teamID);
        if (bet) {
          bet.type = type;
        } else {
          bets.push(new NFLBet(teamInfo.teamID, type))
        }
        setBets([...bets]);

      }} />
  </JcColumn>
}