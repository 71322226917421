import { NFLTeamBetInfo } from "./NFLTeamBetInfo";
import { NFLTeamInfo } from "./NFLTeamInfo";

export class NFLBet {
    constructor(
        public readonly teamID: number,
        public type: "OVER" | "UNDER",
    ) { }

    public getBetInfo(teamInfos: NFLTeamInfo[]): NFLTeamBetInfo | null {
        const teamInfo = teamInfos.find(teamInfo => teamInfo.teamID === this.teamID);
        if (teamInfo) {
            return new NFLTeamBetInfo(teamInfo.name,
                teamInfo.nickname,
                teamInfo.teamID,
                teamInfo.wins,
                teamInfo.losses,
                teamInfo.ties,
                teamInfo.line,
                this.type,
                teamInfo.overOdds,
                teamInfo.underOdds,
                teamInfo.divisionID)
        }
        return null;
    }


    public toJSON(): { teamID: number, type: "OVER" | "UNDER" } {
        return { teamID: this.teamID, type: this.type };
    }

    public static initFromJSON(json: any): NFLBet {
        return new NFLBet(json["teamID"], json["type"])
    }
}

