import React, { useState } from "react";
import { JcCommonProps, JcUtils, OneOf } from "../utils/JcUtils";
import { JcRow } from "../JcSDK/JcBox";
import { useNavigate } from "react-router-dom";
import { JcIconButton } from "../JcSDK/button/JcIconButton";
import { JcClickableText } from "../JcSDK/JcClickableText";
import { JcAppDrawer } from "./JcAppDrawer";


export interface JcAppTitleBarProps extends JcCommonProps {
    readonly location: JcAppLocation;
}

export const getLocationLabel = (location: JcAppLocation): string => {
    switch (location) {
        case "BETS":
            return "bets";
        case "HOME":
            return "home";
        case "PROJECTS":
            return "projects";
        case "PICK-EM":
            return "pick-em";
        case "404":
            return "404";
    }
}

export const getLocationRoute = (location: JcAppLocation): string => {
    switch (location) {
        case "BETS":
            return "/bets";
        case "HOME":
            return "/";
        case "PROJECTS":
            return "/projects";
        case "PICK-EM":
            return "/pick-em";
        case "404":
            return "/404";
    }
}


type JcAppMainLocation = OneOf<typeof MAIN_LOCATIONS>;
export const MAIN_LOCATIONS = ["HOME", "BETS", "PROJECTS"] as const;
export type JcAppLocation = JcAppMainLocation | "404" | "PICK-EM"


export const JcAppTitleBar = (props: JcAppTitleBarProps): React.ReactElement => {

    const navigate = useNavigate();
    const [drawerVisible, setDrawerVisible] = useState(false);

    const renderLocation = (location: JcAppLocation): React.ReactNode => {
        return <JcClickableText
            key={location}
            onClick={() => navigate(getLocationRoute(location))}
            label={getLocationLabel(location)}
            selected={props.location === location} />
    }

    const commonProps = JcUtils.commonProps(props, ["jc-app-title-bar"])
    return <JcRow alignCenter {...commonProps}>
        <JcAppDrawer location={props.location}
            open={drawerVisible}
            onClose={() => setDrawerVisible(false)} />
        <JcRow spacing alignCenter className="jc-app-title-top-left">
            <JcAppTitleText />
            <div>{"/"}</div>
            <JcClickableText
                onClick={() => navigate(getLocationRoute(props.location))}
                label={getLocationLabel(props.location)} />
        </JcRow>
        <JcIconButton className="jc-app-title-hamburger"
            onClick={() => setDrawerVisible(true)}
            icon="jc:hamburger" />
        <JcRow className="jc-app-title-locations">
            {MAIN_LOCATIONS.map(renderLocation)}
        </JcRow>
    </JcRow>;
}


export const JcAppTitleText = (props: JcCommonProps): React.ReactElement => {
    const navigate = useNavigate();
    const commonProps = JcUtils.commonProps(props, ["jc-app-title-text"]);
    return <JcClickableText
        {...commonProps}
        onClick={() => navigate("/")}
        label="jcallard.com" />
}