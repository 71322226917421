
import React, { useCallback, useState } from 'react';
import { JcRow, JcColumn } from '../../../JcSDK/JcBox';
import { NFLBetter } from './models/NFLBetter';
import { NFLBetsTeamLabel } from './NFLBetsTeamLabel';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { JcGrid } from '../../../JcSDK/JcGrid';
import { NFLBetsLineLabel } from './NFLBetsLineLabel';
import { NFLBetsUserLabel } from './NFLBetsUserLabel';
import { NFLTeamInfo } from './models/NFLTeamInfo';
import { JcField } from '../../../JcSDK/JcField';
import { JcFieldGroup } from '../../../JcSDK/JcFieldGroup';
import { JcDropdown } from '../../../JcSDK/button/JcDropdown';

export interface NFLBetsUsersPicksViewProps extends JcCommonProps {
  readonly teamInfos: NFLTeamInfo[];
  readonly nflBetters: NFLBetter[];
}

interface NFLDivisionInfo {
  id: number,
  conference: "AFC" | "NFC",
  name: "EAST" | "NORTH" | "SOUTH" | "WEST",
}

const divisionInfos: NFLDivisionInfo[] = [
  {
    id: 1,
    conference: "AFC",
    name: "EAST",
  }, {
    id: 2,
    conference: "AFC",
    name: "WEST",
  }, {
    id: 3,
    conference: "AFC",
    name: "NORTH",
  }, {
    id: 4,
    conference: "AFC",
    name: "SOUTH",
  }, {
    id: 5,
    conference: "NFC",
    name: "EAST",
  }, {
    id: 6,
    conference: "NFC",
    name: "WEST",
  }, {
    id: 7,
    conference: "NFC",
    name: "NORTH",
  }, {
    id: 8,
    conference: "NFC",
    name: "SOUTH",
  },
]

export const NFLBetsUsersPicksView = (props: NFLBetsUsersPicksViewProps): React.ReactElement<NFLBetsUsersPicksViewProps> => {

  const [division, setDivision] = useState(divisionInfos[0]);
  const teamInfos = props.teamInfos.filter(teamInfo => division.id === teamInfo.divisionID);

  const renderTopRow = useCallback(() => {
    return <>
      <div className="nfl-bets-user-row-header nfl-bets-user-column-header" />
      {teamInfos.map(teamInfo => {
        const labelClassName = JcUtils.classNameString([
          "nfl-bets-user-column-header",
          teamInfos.indexOf(teamInfo) === teamInfos.length - 1 && "last-child",
        ])
        return <NFLBetsTeamLabel
          key={teamInfo.teamID}
          className={labelClassName}
          teamInfo={teamInfo} />
      })}
    </>
  }, [teamInfos])

  const cellRenderer = useCallback((nflBetter: NFLBetter, teamInfo: NFLTeamInfo | "USER", lastChild?: boolean): React.ReactNode => {
    const betInfo = teamInfo !== "USER" && nflBetter.betInfo(teamInfo);
    if (!nflBetter.bets2024) {
      return null;
    }
    else if (teamInfo === "USER") {
      const userClassNames = JcUtils.classNameString([
        "nfl-bets-user-row-header",
        lastChild && "last-child",
      ]);
      return <NFLBetsUserLabel
        key={nflBetter.uid + "*" + teamInfo}
        className={userClassNames}
        nflBetter={nflBetter}
        teamInfos={props.teamInfos} />
    }
    else if (!betInfo) {
      return <div>bet not placed</div>
    }
    const lineClassName = JcUtils.classNameString([
      betInfo.clinchedWin && "nfl-win",
      betInfo.clinchedLoss && "nfl-loss",
    ]);
    return <JcColumn
      key={nflBetter.uid + "*" + betInfo.teamID}
      className="nfl-bets-user-row-box-wrapper"
      alignCenter
      justifyCenter>
      <NFLBetsLineLabel
        className={lineClassName}
        line={betInfo.line}
        overOdds={betInfo.overOdds}
        underOdds={betInfo.underOdds}
        type={betInfo.type} />
    </JcColumn>
  }, [props.teamInfos])

  const commonProps = JcUtils.commonProps(props, ["nfl-users-chart-view"]);

  return <JcColumn {...commonProps} flexGrow>
    <JcGrid topRowRenderer={renderTopRow}
      columns={["USER", ...teamInfos]}
      columnWidth={(col) => col === "USER" ? "calc(2 * var(--jc-default-gap) + var(--jc-image-size-medium))" : "minmax(0, 1fr)"}
      rows={props.nflBetters}
      cellRenderer={cellRenderer} />
    <JcFieldGroup className="nfl-bets-division-selection">
      <JcField label="Division">
        <JcRow>
          <JcDropdown<NFLDivisionInfo>
            position="top"
            keyFunction={d => d.id.toString()}
            value={division}
            options={divisionInfos}
            onChange={setDivision}
            itemRenderer={d => d.conference + " " + d.name} />
        </JcRow>
      </JcField>
    </JcFieldGroup>
  </JcColumn >
}