import React from 'react';
import { JcApp } from './app/JcApp';

class App extends React.Component {

  render() {
    return (
      <JcApp />
    );
  }
}

export default App;
