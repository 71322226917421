
import React from 'react';
import { NFLBetBracket as NFLBetsDivider } from './NFLBetsDivider';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';


interface NFLBetsBoxProps extends JcCommonProps {
  boxAndBelowIsWin?: boolean;
  boxAndAboveIsWin?: boolean;
  showLine?: boolean;
  onClick?: () => void;
  isSelected?: boolean;

  resultType?: "WIN" | "LOSS" | "TIE",
}


export const NFLBetsBox: React.FunctionComponent<NFLBetsBoxProps> = (props: NFLBetsBoxProps) => {

  const commonProps = JcUtils.commonProps(props, [
    "nfl-bets-box-container",
    props.onClick && "jc-clickable",
  ]);

  const boxClassName = JcUtils.classNameString([
    "nfl-bets-box",
    props.resultType === "WIN" && "nfl-win",
    props.resultType === "LOSS" && "nfl-loss",
    props.resultType === "TIE" && "nfl-tie",
    props.isSelected && "nfl-bets-box-selected",
  ])

  return <div {...commonProps} onClick={() => props.onClick && props.onClick()}>

    {props.showLine && <NFLBetsDivider />}
    {props.boxAndBelowIsWin && <NFLBetsDivider isUnder />}
    {props.boxAndAboveIsWin && <NFLBetsDivider isOver />}

    <div className="nfl-bets-box-wrapper">
      <div className={boxClassName} />
    </div>

  </div>

}