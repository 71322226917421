import { createContext, useContext, useState, useEffect } from "react";
import GoogleButton from "react-google-button";
import { JcColumn } from "../JcSDK/JcBox";
import { JcLabel } from "../JcSDK/JcLabel";
import { JcFullPageLoadingIndicator } from "../JcSDK/JcLoadingIndicator";
import { JcNotice } from "../JcSDK/JcNotice";
import { JcAppAuth } from "./service/JcAppAuth";



const JcAppAuthContext = createContext<JcAppAuth | null>(null);

export const useAppAuth = () => {
    const authContext = useContext(JcAppAuthContext);

    if (!authContext) {
        throw new Error("useAuth has to be used within <JcGoogleAuthContext.Provider>");
    }

    return authContext;
};

export const JcAppAuthWrapper = (props: { children: React.ReactElement }): React.ReactElement => {

    const [auth, setAuth] = useState<JcAppAuth | null | "LOADING">("LOADING");

    useEffect(() => {
        JcAppAuth.onAuthChange(setAuth);
    }, []);

    if (auth === "LOADING") {
        return <JcFullPageLoadingIndicator label={<JcLabel label="Loading" />} />
    }
    if (!auth) {
        return <JcColumn alignCenter justifyCenter flexGrow>
            <JcColumn className="nfl-bets-log-in" alignCenter spacing>
                <JcLabel label="Welcome to Jc Bets" />
                <JcNotice message="These are not real bets- it's just for fun." />
                <GoogleButton onClick={() => JcAppAuth.signIn()} />
            </JcColumn>
        </JcColumn>;
    }
    else {
        return <JcAppAuthContext.Provider value={auth}>
            {props.children}
        </JcAppAuthContext.Provider>;
    }
}