export class NFLRecord {
    constructor(
        public readonly id: number,
        public readonly wins: number,
        public readonly losses: number,
        public readonly ties: number,
    ) { }

    public static async getRecord(teamID: number, year: number): Promise<NFLRecord> {
        let val = await fetch(`https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/${year}/types/2/teams/${teamID}/records/0?lang=en&region=us`);
        const json: { type: string, summary: string } = await val.json();
        const parsedRecord = json.summary.split("-");


        const wins = Number(parsedRecord[0]);
        const losses = Number(parsedRecord[1]);
        const ties = parsedRecord.length < 3 ? 0 : Number(parsedRecord[2]);

        return new NFLRecord(teamID, wins, losses, ties);
    }


}