
import React from "react";
import { JcAppTitleBar } from "./JcAppTitleBar";
import { JcPage } from "../JcSDK/JcPage";
import { JcAppFooter } from "./JcAppFooter";
import { JcColumn } from "../JcSDK/JcBox";


export const JcAppPageNotFound = (): React.ReactElement => {

    return <JcPage className="jc-app-page-not-found jc-page-with-title-bar">
        <JcAppTitleBar location="404" />
        <JcColumn className="jc-app-not-found-content">
            <div className="jc-app-not-found-title">Uh Oh!</div>
            <div className="jc-app-not-found-text">Can't find this page.</div>
        </JcColumn>
        <JcAppFooter />
    </JcPage>;
}