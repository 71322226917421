
import React from "react";
import { JcPage } from "../../JcSDK/JcPage";
import { JcAppFooter } from "../JcAppFooter";
import { ProjectCard } from "../projects/ProjectCard";
import { ProjectGroup } from "../projects/ProjectGroup";
import { HomeScreen } from "./HomeScreen";
import { useNavigate } from "react-router-dom";
import { JcAppTitleBar } from "../JcAppTitleBar";


export const JcHomePage = (): React.ReactElement => {
    const navigate = useNavigate();

    return <JcPage className="jc-home-page jc-page-with-title-bar">
        <JcAppTitleBar className="jc-home-title-bar" location="HOME" />
        <HomeScreen />
        <ProjectGroup title="Projects" className="projects" id="projects">

            <ProjectCard title="huh events" tags={["Dart", "Flutter SDK", "Firebase", "Cloud Firestore", "Realtime Database", "Google Cloud Platform"]}>
                <ul>
                    <li>Sole developer in designing, developing, and testing the mobile application using the Flutter SDK</li>
                    <li>Used the NoSQL database to implement friendships, event posting, map population, and event sharing among many other features</li>
                    <li>Integrated Firebase into the app to provide the database along with user authentication to allow for email, Google and Facebook sign in</li>
                </ul>
            </ProjectCard>
            <ProjectCard title="Mediocre Melodies Website" link="https://mediocremelodies.com/" tags={["Typescript", "React", "CSS", "Firebase", "Remote Config"]}>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptas, doloribus rerum eveniet iusto debitis dolores inventore expedita, repellendus nesciunt architecto, nemo consequatur. Iure quidem dolorem dolor vero laudantium quia est.</p>
            </ProjectCard>


        </ProjectGroup>
        <ProjectGroup title="Bets" className="projects" id="projects">
            <ProjectCard title="jcallard.com/bets" onClick={() => navigate("/bets")}>
                <div>{"Check it out >"}</div>
            </ProjectCard>
        </ProjectGroup>

        <JcAppFooter />

    </JcPage>;
}