import React from 'react';
import { JcPopupButton } from './JcPopupButton';
import { JcCommonProps, JcKeyFunction, JcUtils } from '../../utils/JcUtils';
import { JC_LIST_DEFAULT_ITEM_RENDERER, JcList } from '../JcList';
import { JcRow } from '../JcBox';
import { JcIcon } from '../JcIcon';


export interface JcDropdownProps<T> extends JcCommonProps {
    readonly onChange: (T: T) => void;
    readonly options: T[];
    readonly value?: T;
    readonly placeHolder?: React.ReactNode;
    readonly disabled?: boolean;
    readonly position?: "bottom" | "top";
    readonly itemRenderer?: (item: T) => React.ReactNode;
    readonly keyFunction: JcKeyFunction<T>;
}


export const JcDropdown = <T,>(props: JcDropdownProps<T>): React.ReactElement => {


    const itemRenderer = props.itemRenderer ?? JC_LIST_DEFAULT_ITEM_RENDERER;

    const renderDropdownList = (): React.ReactNode => {
        return <JcList className="jc-dropdown-list"
            keyFunction={props.keyFunction}
            selection={props.value}
            items={props.options}
            itemRenderer={props.itemRenderer}
            onSelection={item => {
                props.onChange(item);
                setOpen(false);
            }} />
    }

    const position = props.position ?? "bottom";
    const commonProps = JcUtils.commonProps(props, [
        "jc-dropdown",
        "jc-dropdown-position-" + position,
    ]);

    const [open, setOpen] = React.useState(false);
    return <JcPopupButton {...commonProps}
        trigger={<JcRow alignCenter spacing flexGrow>
            <JcRow justifyCenter flexGrow>{props.value ? itemRenderer(props.value) : props.placeHolder}</JcRow>
            <JcIcon size="extra-small" icon="jc:arrow-down" />
        </JcRow>}
        open={open}
        setOpen={setOpen}
        position={position === "top" ? "top left" : "bottom left"}
        popupContent={renderDropdownList()} />
}