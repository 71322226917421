import React from 'react';
import { JcCommonProps, JcSize, JcUtils, OneOf } from '../utils/JcUtils';

export const JcIconColor = "#";

export const IS_JC_ICON = (label: unknown): label is JcIconType => {
  return JC_ICONS.find(icon => icon === label) != null;
}

const JC_ICONS = [
  "jc:add",
  "jc:account",
  "jc:close",
  "jc:over",
  "jc:under",
  "jc:info",
  "jc:hamburger",
  "jc:instagram",
  "jc:github",
  "jc:linkedin",
  "jc:arrow-down",
  "jc:go-back",
  "jc:send",
  "jc:write",
  "jc:messages",
  "jc:copy"
] as const;


export type JcIconType = OneOf<typeof JC_ICONS>;

export interface JcIconProps extends JcCommonProps {
  readonly icon: JcIconType;
  readonly size?: JcSize;
}

export const JcIcon: React.FunctionComponent<JcIconProps> = (props: JcIconProps) => {

  const size = props.size ?? "small";

  const commonProps = JcUtils.commonProps(props, [
    "jc-icon",
    "jc-icon-size-" + size,
  ]);

  const getImg = (icon: JcIconType): string =>
    "/resources/icons/" + icon.split(":")[1] + ".png"

  return <div {...commonProps}>
    {<img alt={props.icon} className="jc-icon-img" src={getImg(props.icon)} />}
  </div>
}