import { useState } from "react";
import { JcColumn } from "../../../JcSDK/JcBox";
import { JcCommonProps, JcUtils } from "../../../utils/JcUtils";
import { PickEmMatchUpTile } from "./PickEmMatchUpTile";
import { PickEmMatchUp } from "./models/PickEmMatchUp";

export interface PickEmWeekViewProps extends JcCommonProps {
    readonly matchUps: PickEmMatchUp[];
}

export const PickEmMatchUpsView = (props: PickEmWeekViewProps): React.ReactElement => {
    const commonProps = JcUtils.commonProps(props, ["pick-em-match-ups-list"]);
    const [selectedTeamId, setSelectedTeamId] = useState<number>();

    return <JcColumn spacing {...commonProps}>
        {props.matchUps.map((matchUp, index) => <PickEmMatchUpTile
            selectedTeamId={selectedTeamId}
            onTeamSelection={setSelectedTeamId}
            key={index}
            matchUp={matchUp} />)}
    </JcColumn>
}