import { NFLConfig } from "../../nflBets/models/NFLConfig";
import { database } from "../../../../firebase";
import { EspnGameDTO } from "../espnDto/EspnGameDTO";
import { EspnWeekMatchUpsDTO } from "../espnDto/EspnWeekMatchUpsDTO";
import { PickEmMatchUp } from "../models/PickEmMatchUp";
import { onValue, ref, set, Unsubscribe } from "firebase/database";
import { PickEmChoiceDTO } from "../dto/PickEmChoiceDTO";

export class PickEmService {

    public static makeSelection(userId: string, week: number, teamId: number): Promise<void> {
        return set(ref(database, `/pick-em/${2024}/${userId}/${week}`), teamId);
    }

    public static subscribeToChoices(userId: string, onChoices: (choice: PickEmChoiceDTO[]) => void): Unsubscribe {
        return onValue(ref(database, `/pick-em/${2024}/${userId}`), (snapshot) => {
            const choices: PickEmChoiceDTO[] = [];
            snapshot.forEach(doc => {
                const week: number = parseInt(doc.key);
                const teamId: number = doc.val();
                choices.push({ week, teamId });
            });
            onChoices(choices);
        });
    }

    public static async getMatchUps(week: number): Promise<PickEmMatchUp[]> {
        const config = await NFLConfig.getConfig();
        const year = config.year;

        let val = await fetch(`https://cdn.espn.com//core/nfl/schedule?xhr=1&year=${year}&week=${week}&seasontype=2`);

        const json: EspnWeekMatchUpsDTO = await val.json();

        return Object.entries(json.content.schedule)
            .reduce((prev: EspnGameDTO[], [_, day]): EspnGameDTO[] => [...prev, ...day.games], [])
            .map(PickEmMatchUp.initFromEspnGame.bind(PickEmMatchUp));
    }
}