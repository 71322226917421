import { EspnGameCompetitorDTO } from "../espnDto/EspnGameCompetitorDTO";
import { PickEmRecord } from "./PickEmRecord";

export class PickEmTeamInfo {
    public readonly id: number;
    public readonly name: string;
    public readonly nickname: string;
    public readonly logo: string;
    public readonly record: PickEmRecord;

    constructor(id: number, name: string, nickname: string, logo: string, record: PickEmRecord) {
        this.id = id;
        this.name = name;
        this.nickname = nickname;
        this.logo = logo;
        this.record = record;
    }

    public static initFromEspnGameCompetitor(competitor: EspnGameCompetitorDTO): PickEmTeamInfo {
        return new PickEmTeamInfo(
            parseInt(competitor.team.id),
            competitor.team.name,
            competitor.team.displayName,
            competitor.team.logo,
            PickEmRecord.initFromString(competitor.records[0].summary)
        )
    }
}