import * as React from "react";
import { JcCommonProps, JcUtils } from "../../utils/JcUtils";
import { JcBox, JcColumn } from "../../JcSDK/JcBox";
import { JcUserDTO } from "../users/dto/JcUserDTO";
import { JcLoadingIndicator } from "../../JcSDK/JcLoadingIndicator";
import { JcImage } from "../../JcSDK/JcImage";
import { JcLabel } from "../../JcSDK/JcLabel";
import { ChatBlockInfo, ChatUtils } from "./ChatUtils";
import { ChatMessageDTO } from "./dto/ChatMessageDTO";
import { useState } from "react";
import { JcDialogButton } from "../../JcSDK/button/JcDialogButton";

export interface ChatMessageBlockProps extends JcCommonProps {
    block: ChatBlockInfo;
    userInfo: JcUserDTO | null;
    displayInfo?: (userInfo: JcUserDTO) => React.ReactNode;
}

export const ChatMessageBlock = (props: ChatMessageBlockProps): React.ReactElement<ChatMessageBlockProps> => {
    const { block, userInfo, displayInfo, ...otherProps } = props;

    const commonProps = JcUtils.commonProps(otherProps, [
        "chat-message-block",
        block.sentByCurrentUser ? "chat-message-block-right" : "chat-message-block-left",
    ])


    const renderProfilePic = (): React.ReactNode => {
        if (block.sentByCurrentUser) {
            return null;
        }
        else if (userInfo == null) {
            return <JcLoadingIndicator size="small" />
        }
        else if (displayInfo == null) {
            return <JcImage circle
                url={userInfo.photoURL}
                alt={userInfo.displayName} />
        }
        else {
            return <ChatProfilePicture dialogContent={displayInfo(userInfo)} userInfo={userInfo} />
        }
    }

    const renderNameLabel = (): React.ReactNode => {
        if (block.sentByCurrentUser) {
            return null;
        }

        const displayName = userInfo?.displayName ?? "Loading ...";
        return <JcLabel className="chat-message-block-name-label" label={displayName} />
    }

    const renderTime = (): React.ReactNode => {
        if (!block.hasTimePassed) {
            return null;
        }
        const formattedTime = ChatUtils.formatTimestamp(block.timestamp)
        return <JcLabel className="chat-message-time-label" label={formattedTime} />
    }

    const bubbleRenderer = (message: ChatMessageDTO): React.ReactNode => {
        return <JcBox className="chat-message-bubble">{message.message}</JcBox>
    }

    return <JcColumn {...commonProps} spacing>
        {renderTime()}
        <JcBox className="chat-message-block-row">
            {renderProfilePic()}
            <JcColumn className="chat-message-bubbles">
                {renderNameLabel()}
                {block.messages.map(bubbleRenderer)}
            </JcColumn>
        </JcBox>
    </JcColumn>;
}

export interface ChatProfilePictureProps {
    userInfo: JcUserDTO;
    dialogContent: React.ReactNode;
}

export const ChatProfilePicture = (props: ChatProfilePictureProps): React.ReactElement<ChatProfilePictureProps> => {
    const { userInfo, dialogContent } = props;
    const [open, setOpen] = useState(false);

    return <JcDialogButton
        className="chat-profile-picture"
        open={open}
        popupContent={dialogContent}
        setOpen={setOpen}
        trigger={<JcImage circle
            url={userInfo.photoURL}
            alt={userInfo.displayName} />} />
}