
import React from "react";
import { JcPage } from "../../JcSDK/JcPage";
import { JcAppFooter } from "../JcAppFooter";
import { JcAppTitleBar } from "../JcAppTitleBar";
import { JcColumn } from "../../JcSDK/JcBox";
import { JcImage } from "../../JcSDK/JcImage";

type Project = {
    id: string;
    numPics: number;
    label: string;
}

const projects: Project[] = [{
    id: "coffee",
    numPics: 8,
    label: "Coffee table"
}, {
    id: "bike",
    numPics: 3,
    label: "Bike rack"
}, {
    id: "bar",
    numPics: 3,
    label: "Mini-bar"
}, {
    id: "desk",
    numPics: 8,
    label: "Desk"
}, {
    id: "art",
    numPics: 2,
    label: "Wood-encased mirror wall art"
}]

export const ProjectPage = (): React.ReactElement => {
    return <JcPage className="jc-project-page jc-page-with-title-bar">
        <JcAppTitleBar location="PROJECTS" />
        <div className="projects-display">
            {projects.map((project, i) => <JcColumn key={i} className="project-portfolio-card">
                <div className="project-image-container">
                    <JcImage className="project-image"
                        key={project.id}
                        alt={project.label}
                        size="unset"
                        url={"/resources/images/projects/" + project.id + "1.png"} />
                    <div className="project-image-overlay">
                        <div className="project-image-text">{project.label}</div>
                    </div>
                </div>
            </JcColumn>)}
        </div>
        <JcAppFooter />
    </JcPage>;
}