import { JcCommonProps, JcUtils } from "../../utils/JcUtils";
import { JcRow, JcColumn } from "../JcBox";
import { JcButton } from "../button/JcButton";
import { JcIconButton } from "../button/JcIconButton";

export interface JcDialogProps extends JcCommonProps {
  readonly title?: React.ReactNode;
  readonly onClose?: () => void;
  readonly onOk?: () => void;
  readonly okButton?: React.ReactNode;
  readonly okDisabled?: boolean;
  readonly closeButton?: React.ReactNode;
  readonly children: React.ReactNode;
  readonly hideCloseButton?: boolean;
}

export const JcDialog = (props: JcDialogProps): React.ReactElement<JcDialogProps> => {

  const commonProps = JcUtils.commonProps(props, [
    "jc-dialog",
    "jc-app-white-background"
  ]);

  return <JcColumn {...commonProps}>
    {(props.title || (props.onClose && !props.hideCloseButton))
      && <JcRow flexGrow className="jc-dialog-top-bar">
        {props.title}
        {props.onClose && !props.hideCloseButton
          && <JcIconButton
            className="jc-dialog-close-icon"
            onClick={() => props.onClose && props.onClose()}
            icon="jc:close"
            size="extra-small" />}
      </JcRow>}
    {props.children}
    <JcRow flexGrow spacing className="jc-dialog-bottom-bar">
      {props.onOk
        && <JcButton
          disabled={props.okDisabled}
          className="jc-dialog-ok"
          onClick={() => props.onOk && props.onOk()} >{props.okButton || "Ok"}
        </JcButton>}
      {props.onClose
        && <JcButton
          className="jc-dialog-close"
          onClick={() => props.onClose && props.onClose()} >{props.closeButton || "Close"}
        </JcButton>}
    </JcRow>
  </JcColumn>
}