import React from 'react';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';
import { JcRow } from './JcBox';


export interface JcCheckboxProps extends JcCommonProps {
  readonly label: React.ReactNode;
  readonly value: boolean;
  readonly onClick: (clicked: boolean) => void;
}


export const JcCheckbox: React.FunctionComponent<JcCheckboxProps> = (props: JcCheckboxProps) => {

  const commonProps = JcUtils.commonProps(props, ["jc-checkbox"]);

  return <JcRow {...commonProps} flexGrow alignCenter spacing>
    <input value={props.value ? 1 : 0} onClick={() => props.onClick(!props.value)} type="checkbox" />
    {props.label}
  </JcRow>
}