import { NFLBet } from "./NFLBet";
import { NFLConfig } from "./NFLConfig";
import { NFLRecord } from "./NFLRecord";
import { NFLTeamBetInfo } from "./NFLTeamBetInfo";
import { NFLTeamLine } from "./NFLTeamLine";

export class NFLTeamInfo extends NFLTeamLine {
    constructor(
        name: string,
        nickname: string,
        id: number,
        division: number,
        line: number,
        overOdds: number,
        underOdds: number,
        public readonly wins: number,
        public readonly losses: number,
        public readonly ties: number,
    ) {
        super(name, nickname, id, division, line, overOdds, underOdds)
    }

    public static async getTeamInfos(): Promise<NFLTeamInfo[]> {
        const config = await NFLConfig.getConfig();

        const lines = config.teamLines;
        const year = config.year;

        return Promise.all(
            lines
                .sort((a, b) => (a.divisionID === b.divisionID ? a.name > b.name : a.divisionID > b.divisionID) ? 1 : -1)
                .map(async line => {
                    const record = await NFLRecord.getRecord(line.teamID, year)
                    return new NFLTeamInfo(
                        line.name,
                        line.nickname,
                        line.teamID,
                        line.divisionID,
                        line.line,
                        line.overOdds,
                        line.underOdds,
                        record.wins,
                        record.losses,
                        record.ties,
                    );
                })
        );
    }

    public getBetInfo(bets: NFLBet[]): NFLTeamBetInfo | null {
        const bet = bets.find(bet => this.teamID === bet.teamID)
        if (bet) {
            return new NFLTeamBetInfo(this.name,
                this.nickname,
                this.teamID,
                this.wins,
                this.losses,
                this.ties,
                this.line,
                bet.type,
                this.overOdds,
                this.underOdds,
                this.divisionID)
        }
        return null;
    }


}