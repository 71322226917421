import { EspnGameDTO } from "../espnDto/EspnGameDTO";
import { PickEmStatus } from "./PickEmStatus";
import { PickEmTeamInfo } from "./PickEmTeamInfo";


export class PickEmMatchUp {

    public readonly startTime: Date;
    public readonly homeInfo: PickEmTeamInfo;
    public readonly awayInfo: PickEmTeamInfo;
    public readonly status: PickEmStatus;

    constructor(startTime: Date, homeInfo: PickEmTeamInfo, awayInfo: PickEmTeamInfo, status: PickEmStatus) {
        this.homeInfo = homeInfo;
        this.awayInfo = awayInfo;
        this.startTime = startTime;
        this.status = status;
    }

    public static initFromEspnGame(game: EspnGameDTO): PickEmMatchUp {
        const competitors = game.competitions[0].competitors;
        const [homeIndex, awayIndex] = competitors[0].homeAway === "home" ? [0, 1] : [1, 0];

        const homeCompetitor = competitors[homeIndex];
        const awayCompetitor = competitors[awayIndex];

        const homeInfo = PickEmTeamInfo.initFromEspnGameCompetitor(homeCompetitor);
        const awayInfo = PickEmTeamInfo.initFromEspnGameCompetitor(awayCompetitor);
        const status: PickEmStatus = new PickEmStatus(
            parseInt(homeCompetitor.score),
            parseInt(awayCompetitor.score),
            game.status.type.completed,
            game.status.period,
            game.status.displayClock,
        )
        return new PickEmMatchUp(new Date(Date.parse(game.date)), homeInfo, awayInfo, status,)
    }
}