export class NFLTeamLine {
    constructor(
        public readonly name: string,
        public readonly nickname: string,
        public readonly teamID: number,
        public readonly divisionID: number,
        public readonly line: number,
        public readonly overOdds: number,
        public readonly underOdds: number,
    ) { }

    public static initFromJSON(json: any): NFLTeamLine {
        return new NFLTeamLine(
            json["teamName"],
            json["nickname"],
            json["teamID"],
            json["division"],
            json["line"],
            json["overOdds"],
            json["underOdds"],
        )
    }
}