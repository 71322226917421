export class PickEmRecord {
    public readonly wins: number;
    public readonly losses: number;
    public readonly ties: number;
    constructor(wins: number, losses: number, ties: number) {
        this.wins = wins;
        this.losses = losses;
        this.ties = ties;
    }

    public static initFromString(recordString: string): PickEmRecord {
        const split = recordString.split("-");
        return new PickEmRecord(
            parseInt(split[0]),
            parseInt(split[1]),
            parseInt(split[2] ?? "0"),
        )
    }

    public recordString(): string {
        const counts = [this.wins, this.losses];
        if (this.ties > 0) {
            counts.push(this.ties);
        }
        return counts.join("-");
    }
}