import Popup from "reactjs-popup";
import { JcUtils } from "../../utils/JcUtils";
import { JcButton, JcButtonProps } from "./JcButton";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";
import { IS_JC_ICON, JcIconType } from "../JcIcon";
import { JcIconButton } from "./JcIconButton";


export interface JcPopupButtonProps extends Omit<JcButtonProps, "onClick" | "selected" | "children">, Pick<React.RefAttributes<PopupActions>, "ref">, Pick<PopupProps, "modal" | "position"> {
  readonly open: boolean;
  readonly trigger: JcIconType | React.ReactNode;
  readonly setOpen: (open: boolean) => void;
  readonly popupContent: React.ReactNode;
}

export const JcPopupButton = (props: JcPopupButtonProps): React.ReactElement<JcPopupButtonProps> => {

  const { open, setOpen, popupContent, ref, modal, position, ...buttonProps } = JcUtils.otherProps(props);
  const commonProps = JcUtils.commonProps(props, [
    "jc-popup-button",
    props.className
  ])
  const popupClassName = JcUtils.classNameString(commonProps.className?.split(" ").map(name => name + "-popup") ?? []);

  const renderTrigger = (isOpen: boolean) => {
    const props = { ...buttonProps, ...commonProps };
    return IS_JC_ICON(props.trigger)
      ? <JcIconButton icon={props.trigger} {...props} />
      : <JcButton {...props}>{props.trigger}</JcButton>
  }

  return <Popup ref={props.ref}
    className={popupClassName}
    position={props.position}
    arrow={false}
    modal={props.modal}
    trigger={renderTrigger}
    disabled={props.disabled}
    open={props.open}
    onOpen={() => props.setOpen(true)}
    onClose={() => props.setOpen(false)}>
    {props.popupContent}
  </Popup>
}