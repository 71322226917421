import React from 'react';
import { JcUtils } from '../../../utils/JcUtils';


interface NFLBetsBracketProps {
  isUnder?: boolean;
  isOver?: boolean;
}


export const NFLBetBracket: React.FunctionComponent<NFLBetsBracketProps> = (props: NFLBetsBracketProps) => {

  const sidesClassName = JcUtils.classNameString([
    props.isUnder && "nfl-bet-box-sides-under",
    props.isOver && "nfl-bet-box-sides-over",
    "box-decoration",
    "nfl-bet-box-sides",
  ]);

  const lineClassName = JcUtils.classNameString([
    props.isUnder && "nfl-bet-box-line-under",
    props.isOver && "nfl-bet-box-line-over",
    "box-decoration",
    "nfl-bet-box-line",
  ]);

  return <div className="nfl-bets-bracket">
    <div className={" nfl-bet-box-sides-left " + sidesClassName} />
    <div className={"nfl-bet-box-sides-right " + sidesClassName} />

    <div className={lineClassName} />
  </div>
}