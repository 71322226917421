
import React from 'react';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { JcRow, JcColumn } from '../../../JcSDK/JcBox';
import { JcLabel } from '../../../JcSDK/JcLabel';
import { JcIcon } from '../../../JcSDK/JcIcon';


interface NFLBetsLineLabelProps extends JcCommonProps {
  line: number;
  type?: "OVER" | "UNDER";
  overOdds: number;
  underOdds: number;
}


export const NFLBetsLineLabel: React.FunctionComponent<NFLBetsLineLabelProps> = (props: NFLBetsLineLabelProps) => {

  const commonProps = JcUtils.commonProps(props, ["nfl-bets-line-label"]);

  const oddsToString = (odds: number): string => odds < 0
    ? (odds.toString())
    : ("+" + odds.toString());

  const renderOdds = (): React.ReactNode => {
    if (props.type === "OVER") {
      return oddsToString(props.overOdds);
    }
    else if (props.type === "UNDER") {
      return oddsToString(props.underOdds);
    }
    return oddsToString(props.overOdds) + "/" + oddsToString(props.underOdds);
  }


  return <JcRow {...commonProps} justifyCenter alignCenter>
    <JcColumn alignCenter>
      <JcLabel label={props.type ?? "-"} />
      <JcLabel label={props.line.toString()} />
      {renderOdds()}
    </JcColumn>
    {props.type === "OVER" && <JcIcon icon="jc:over" />}
    {props.type === "UNDER" && <JcIcon icon="jc:under" />}
  </JcRow>

}