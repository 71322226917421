import React from 'react';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';


export interface JcFieldGroupProps extends JcCommonProps {
  readonly children: React.ReactNode;
}


export const JcFieldGroup: React.FunctionComponent<JcFieldGroupProps> = (props: JcFieldGroupProps) => {

  const commonProps = JcUtils.commonProps(props, ["jc-field-group"]);

  return <div {...commonProps}>
    {props.children}
  </div>;
}