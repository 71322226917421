import React from 'react';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';


export interface JcLabelProps extends JcCommonProps {
  readonly label: string;
}


export const JcLabel: React.FunctionComponent<JcLabelProps> = (props: JcLabelProps) => {

  const commonProps = JcUtils.commonProps(props, ["jc-label"]);

  return <div {...commonProps}>
    {props.label}
  </div>
}