import * as React from "react";
import { useState, useEffect } from "react";
import { ChatGroupDTO } from "./dto/ChatGroupDTO";
import { ChatService } from "./service/ChatService";
import { JcBox, JcColumn, JcRow } from "../../JcSDK/JcBox";
import { JcLabel } from "../../JcSDK/JcLabel";

export interface ChatGroupProps {
    readonly chatId: string;
}


export const ChatGroup = (props: ChatGroupProps): React.ReactElement => {
    const [chatGroup, setChatGroup] = useState<ChatGroupDTO>();

    useEffect(() => {
        return ChatService.subscribeToChatGroup(props.chatId, setChatGroup);
    }, [props.chatId]);

    return <JcRow spacing>
        <JcBox alignCenter justifyCenter className="chat-circle">
            {chatGroup?.title?.charAt(0)}
        </JcBox>
        <JcColumn className="chat-group-column">
            <JcLabel label={chatGroup ? chatGroup.title : "Loading ..."} />
            {chatGroup ? chatGroup.lastSentMessage.message : "..."}
        </JcColumn>
    </JcRow>
}