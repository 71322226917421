import { NFLTeamInfo } from "./NFLTeamInfo";

export type NFLSortByOptions = "WINS" | "DIV";

export class NFLTeamBetInfo extends NFLTeamInfo {

    public static readonly BET_AMOUNT = 5;

    constructor(
        name: string,
        nickname: string,
        teamID: number,
        wins: number,
        losses: number,
        ties: number,
        line: number,
        public readonly type: "OVER" | "UNDER",
        overOdds: number,
        underOdds: number,
        division: number,
    ) {
        super(name, nickname, teamID, division, line, overOdds, underOdds, wins, losses, ties);
    }

    private static compareTeams = (sortBy: NFLSortByOptions, a: NFLTeamInfo, b: NFLTeamInfo): boolean => {
        if (sortBy === "WINS") {
            if (a.wins !== b.wins) {
                return a.wins > b.wins;
            }
            if (a.losses + a.ties !== b.losses + b.ties) {
                return a.losses + a.ties < b.losses + b.ties
            }
        } else if (sortBy === "DIV") {
            if (a.divisionID !== b.divisionID) {
                return a.divisionID > b.divisionID;
            }
        }
        return a.name > b.name
    }

    private static calcDecimalOdds(odds: number): number {
        if (odds < 0) {
            return (100 / -odds) + 1
        }
        return (odds / 100) + 1
    }

    public calcEarnings(): number {
        if (!this.clinchedWin) {
            return 0;
        }
        const odds = this.type === "OVER"
            ? this.overOdds
            : this.underOdds;

        return NFLTeamBetInfo.BET_AMOUNT * NFLTeamBetInfo.calcDecimalOdds(odds);
    }

    public static teamSorter = (sortBy: NFLSortByOptions, asc: boolean, a: NFLTeamInfo, b: NFLTeamInfo) => {
        const ret = asc ? 1 : -1;
        return this.compareTeams(sortBy, a, b) ? ret : -ret;
    }

    public get clinchedWin(): boolean {
        return (this.type === "UNDER" && this.losses + this.ties > 17 - this.line)
            || (this.type === "OVER" && this.wins > this.line);
    }

    public get clinchedLoss(): boolean {
        return (this.type === "UNDER" && this.wins > this.line)
            || (this.type === "OVER" && this.losses + this.ties > 17 - this.line);
    }

}