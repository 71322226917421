import React from 'react';
import { JcRow } from '../JcSDK/JcBox';
import { JcIcon } from '../JcSDK/JcIcon';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';

export const JcAppFooter = (props: JcCommonProps): React.ReactElement => {
    const commonProps = JcUtils.commonProps(props, ["jc-app-footer"]);
    return <footer {...commonProps}>
        <JcRow spacing className="jc-app-social">
            <a href="https://github.com/jackcallard" target="_blank" rel="noreferrer">
                <JcIcon icon="jc:github"
                    size="small"
                    className="jc-app-social-icon" />
            </a>
            <a href="https://www.instagram.com/jackcallard" target="_blank" rel="noreferrer">
                <JcIcon icon="jc:instagram"
                    size="small"
                    className="jc-app-social-icon" />
            </a>
            <a href="https://www.linkedin.com/in/jackcallard/" target="_blank" rel="noreferrer">
                <JcIcon icon="jc:linkedin"
                    size="small"
                    className="jc-app-social-icon" />
            </a>
        </JcRow>
        <div className="jc-app-copyright">2022-2023 Jack Callard</div>
    </footer>;
}