import { JcUtils } from '../../utils/JcUtils';
import { JcButton, JcButtonProps } from './JcButton';
import { JcIcon, JcIconProps } from '../JcIcon';

import React, { forwardRef } from 'react';


export interface JcIconButtonProps extends JcIconProps, Omit<JcButtonProps, "children" | "noBorder"> { }

export const JcIconButton = forwardRef((props: JcIconButtonProps, ref: React.ForwardedRef<HTMLButtonElement>): React.ReactElement => {
  const commonProps = JcUtils.commonProps(props, ["jc-icon-button"]);
  const { icon, size, ...buttonProps } = JcUtils.otherProps(props);

  return <JcButton ref={ref} {...buttonProps} {...commonProps} noBorder>
    <JcIcon icon={icon} size={size} />
  </JcButton>
})