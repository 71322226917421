
import React, { useState } from 'react';
import { JcImage } from '../../../JcSDK/JcImage';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { NFLBetsAccountView } from './NFLBetsAccountView';
import { NFLBetter } from './models/NFLBetter';
import { JcDialogButton } from '../../../JcSDK/button/JcDialogButton';
import { NFLTeamInfo } from './models/NFLTeamInfo';

export interface NFLBetsUserLabelProps extends JcCommonProps {
  readonly nflBetter: NFLBetter;
  readonly teamInfos: NFLTeamInfo[];
}

export const NFLBetsUserLabel = (props: NFLBetsUserLabelProps): React.ReactElement<NFLBetsUserLabelProps> => {

  const commonProps = JcUtils.commonProps(props, ["nfl-bets-user-label"]);

  const [isOpen, setOpen] = useState(false);

  return <JcDialogButton {...commonProps}
    noBorder
    trigger={<JcImage
      alt="profile picture"
      className="nfl-bets-user-label-image"
      size="medium"
      url={props.nflBetter.photoURL} />}
    setOpen={setOpen}
    open={isOpen}
    popupContent={<NFLBetsAccountView
      className="nfl-bets-user-info"
      teamInfos={props.teamInfos}
      nflBetter={props.nflBetter} />} />
}