import React, { createContext, useContext, useEffect, useState } from "react";
import { NFLBetter } from "./nflBets/models/NFLBetter";
import { JcFullPageLoadingIndicator } from "../../JcSDK/JcLoadingIndicator";
import { JcLabel } from "../../JcSDK/JcLabel";
import { GamesNewUserView } from "./GamesNewUserView";
import { JcAppAuthWrapper, useAppAuth } from "../JcAppAuthWrapper";



export const GamesAuthWrapper = (props: { children: React.ReactElement }): React.ReactElement => {
    return <JcAppAuthWrapper>
        <GamesAccountWrapper>
            {props.children}
        </GamesAccountWrapper>
    </JcAppAuthWrapper>
}

const JcAccountContext = createContext<NFLBetter | null>(null);

export const useAccount = () => {
    const accountContext = useContext(JcAccountContext);

    if (!accountContext) {
        throw new Error("useAccount has to be used within <JcAccountContext.Provider>");
    }

    return accountContext;
};


export const GamesAccountWrapper = (props: { children: React.ReactElement }): React.ReactElement => {

    const [nflBetter, setNFLBetter] = useState<NFLBetter | null | "LOADING">("LOADING");
    const auth = useAppAuth();

    useEffect(() => {
        NFLBetter.onBetterSnapshot(auth.uid, setNFLBetter);
    }, [auth]);

    if (nflBetter === "LOADING") {
        return <JcFullPageLoadingIndicator label={<JcLabel label="Loading" />} />;
    }
    else if (!nflBetter) {
        return <GamesNewUserView />
    }
    else {
        return <JcAccountContext.Provider value={nflBetter}>
            {props.children}
        </JcAccountContext.Provider>;
    }
}