import { JcCommonProps, JcUtils } from "../utils/JcUtils";
import * as React from "react";
import { JcColumn } from "./JcBox";
import { JcSelectionBar, JcSelectionOption } from "./button/JcButtonBar";
import { useMemo } from "react";


export interface JcTab<T extends string> extends JcCommonProps {
    readonly id: T;
    readonly content: React.ReactNode;
    readonly label: React.ReactNode;
    readonly disabled?: boolean;
}

export interface JcTabsProps<T extends string> extends JcCommonProps {
    readonly tabs: JcTab<T>[];
    readonly activeTab: T;
    readonly onTabChange: (id: T) => void;
}

export const JcTabs = <T extends string,>(props: JcTabsProps<T>): React.ReactElement<JcTabsProps<T>> => {
    const { tabs, activeTab, onTabChange, className, ...otherCommonProps } = props;
    const selectionOptions: JcSelectionOption<T>[] = useMemo(() => tabs.map(tab => ({
        id: tab.id,
        label: tab.label,
        disabled: tab.disabled,
    })), [tabs]);
    const tabsClassName = JcUtils.classNameString([className, "jc-tabs"]);

    return <JcColumn {...otherCommonProps} className={tabsClassName}>
        <JcSelectionBar selectedID={props.activeTab}
            onClick={onTabChange}
            options={selectionOptions} />
        {tabs.map(tab => {
            const tabCommonProps = JcUtils.commonProps(tab, [tab.id !== activeTab && "jc-hidden-tab", "jc-tab"])
            return <JcColumn key={tab.id} {...tabCommonProps}>
                {tab.content}
            </JcColumn>
        })}
    </JcColumn>
}
