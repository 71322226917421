import React from 'react';
import { JcFadeIn } from '../../JcSDK/JcFadeIn';


interface ProjectCardProps {
    title: string;
    githubLink?: string;
    children: JSX.Element;
    link?: string;
    onClick?: () => void;
    tags?: string[];
}

export const ProjectCard = (props: ProjectCardProps): React.ReactElement => {

    return <JcFadeIn>
        <div className={`jc-gradient project ${props.link || props.onClick ? "jc-clickable" : ""}`} onClick={() => {
            props.onClick && props.onClick();
            props.link && window.open(props.link);
        }
        }>
            <h3>{props.title}</h3>
            {props.children}
            <div className="tags">
                {props.tags?.map((tag) => <div className="tag" key={tag}>{tag}</div>)}
            </div>
        </div>
    </JcFadeIn>;
}