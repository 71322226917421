import { JcAppAuthWrapper } from "../JcAppAuthWrapper";
import { JcPage } from "../../JcSDK/JcPage";
import { JcCommonProps } from "../../utils/JcUtils";
import * as React from "react";
import { ChatMessenger } from "./ChatMessenger";

export const ChatPage = (props: JcCommonProps): React.ReactElement<JcCommonProps> => {
    return <JcPage className="chat-page">
        <JcAppAuthWrapper>
            <ChatMessenger />
        </JcAppAuthWrapper>
    </JcPage>
}


