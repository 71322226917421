import { JcPopupButton, JcPopupButtonProps } from "./JcPopupButton";
import { JcDialog } from "../dialog/JcDialog";


export interface JcDialogButtonProps extends Omit<JcPopupButtonProps, "modal"> {
  readonly title?: React.ReactNode;
}

export const JcDialogButton = (props: JcDialogButtonProps): React.ReactElement<JcDialogButtonProps> => {

  const { title, popupContent, ...popupButtonProps } = props;

  return <JcPopupButton {...popupButtonProps}
    modal={true}
    popupContent={
      <JcDialog
        title={props.title}
        onOk={() => props.setOpen(false)}
        onClose={() => props.setOpen(false)}>
        {popupContent}
      </JcDialog>
    } />
}