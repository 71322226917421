import React, { useState } from 'react';
import { JcDialogButton } from "../../JcSDK/button/JcDialogButton";
import { JcRow, JcColumn } from "../../JcSDK/JcBox";
import { JcImage } from "../../JcSDK/JcImage";
import { JcFadeIn } from '../../JcSDK/JcFadeIn';
import { JcIcon } from '../../JcSDK/JcIcon';



export const HomeScreen = (): React.ReactElement => {

  const [isContactOpen, setContactOpen] = useState(false);

  return <JcFadeIn>
    <JcColumn justifyCenter className="home-screen" id="home-screen">
      <JcRow className="home-meet-me-row">
        <JcColumn justifyCenter className="title">
          <div className="home-name">Jack Callard</div>
          <div className="home-role">Software Developer</div>
        </JcColumn>
        <JcImage className="home-moji"
          url="/resources/images/moji.png"
          size="xx-large"
          alt="Jack at Computer"
        />
      </JcRow>

      <JcDialogButton open={isContactOpen}
        trigger={"Contact"}
        className="contact-me jc-clickable jc-gradient"
        setOpen={setContactOpen}
        popupContent={<div className="home-contact-me-content">Lol got ya</div>} />

      <div className="arrow-down" onClick={
        () => {
          const homeScreenHeight = document.getElementById("home-screen")!.offsetHeight;
          document.getElementById("root")!.scrollTo({ top: homeScreenHeight, behavior: "smooth" });
        }
      }>
        <JcIcon className="arrow-down-icon"
          size="large"
          icon="jc:arrow-down"
        />
      </div>
    </JcColumn>
  </JcFadeIn>
}