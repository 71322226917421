import { JcRow, JcColumn } from '../../../JcSDK/JcBox';
import React, { useState } from 'react';

import { NFLSortByOptions, NFLTeamBetInfo } from './models/NFLTeamBetInfo';
import { JcLabel } from '../../../JcSDK/JcLabel';
import { JcSelectionBar } from '../../../JcSDK/button/JcButtonBar';
import { JcGrid } from '../../../JcSDK/JcGrid';
import { NFLBetsTeamLabel } from './NFLBetsTeamLabel';
import { JcUtils } from '../../../utils/JcUtils';
import { NFLBetsLineLabel } from './NFLBetsLineLabel';
import { NFLBetsBox } from './NFLBetsBox';
import { NFLTeamInfo } from './models/NFLTeamInfo';
import { NFLBet } from './models/NFLBet';
import { JcIcon } from '../../../JcSDK/JcIcon';


interface NFLBetsChartProps {
  teamInfos: NFLTeamInfo[];
  bets: NFLBet[];
  showSelection?: boolean;
  onClick?: (teamInfo: NFLTeamInfo, type: "OVER" | "UNDER") => void;
}


export const NFLBetsGraph: React.FunctionComponent<NFLBetsChartProps> = (props: NFLBetsChartProps) => {

  const [sortBy, setSortBy] = useState<NFLSortByOptions>("DIV");
  const [asc, setAsc] = useState(true);


  const teams = props.teamInfos.sort((a, b) => NFLTeamBetInfo.teamSorter(sortBy, asc, a, b));

  type RowT = "TEAM" | number | "ODDS";

  const cellRenderer = (row: RowT, teamInfo: NFLTeamInfo): React.ReactNode => {
    const betInfo = teamInfo.getBetInfo(props.bets);

    const sharedClassName = JcUtils.classNameString([
      betInfo?.clinchedWin && "nfl-win",
      betInfo?.clinchedLoss && "nfl-loss",
    ]);
    if (row === "TEAM") {
      return <NFLBetsTeamLabel key={teamInfo.teamID + "-label"} className={sharedClassName} teamInfo={teamInfo} />
    } else if (row === "ODDS") {
      return <NFLBetsLineLabel
        key={teamInfo.teamID + "-line"}
        className={sharedClassName}
        line={teamInfo.line}
        type={betInfo?.type}
        underOdds={teamInfo.underOdds}
        overOdds={teamInfo.overOdds} />
    }
    let resultType: "WIN" | "LOSS" | "TIE" | undefined;

    const indexFromTop = row;
    const indexFromBottom = 16 - row;

    // We want to display wins at the bottom, losses & ties at the top
    if (teamInfo.wins >= indexFromBottom + 1) {
      resultType = "WIN";
    } else if (teamInfo.ties > indexFromTop) {
      resultType = "TIE";
    } else if (teamInfo.ties + teamInfo.losses > indexFromTop) {
      resultType = "LOSS";
    }

    let onClick: (() => void) | undefined;
    let isSelected: boolean | undefined;

    if (indexFromBottom + 1 > teamInfo.line) {
      onClick = props.onClick && (() => props.onClick && props.onClick(teamInfo, "OVER"));
      isSelected = props.showSelection && betInfo?.type === "OVER";
    }
    else {
      onClick = props.onClick && (() => props.onClick && props.onClick(teamInfo, "UNDER"));
      isSelected = props.showSelection && betInfo?.type === "UNDER";
    }

    return <NFLBetsBox key={`${teamInfo.teamID}-${indexFromBottom}`}
      onClick={onClick}
      isSelected={isSelected}
      resultType={resultType}
      showLine={!betInfo?.type && indexFromBottom + 1 === Math.ceil(teamInfo.line)}
      boxAndBelowIsWin={betInfo?.type === "UNDER" && indexFromBottom + 1 === Math.floor(teamInfo.line)}
      boxAndAboveIsWin={betInfo?.type === "OVER" && indexFromBottom + 1 === Math.ceil(teamInfo.line)} />
  }

  const onCopy = () => {
    const textToCopy = props.teamInfos.reduce((textVal, teamInfo) => {
      const betInfo = teamInfo.getBetInfo(props.bets);
      if (betInfo) {
        return textVal + `${(betInfo.type === "OVER" ? "⬆️" : "⬇️")} ${betInfo.nickname} ${betInfo.type} ${betInfo.line}\n`
      }
      return textVal;
    }, "My bets are in!\n\n");
    navigator.clipboard.writeText(textToCopy + "\nMake your picks at jcallard.com/bets")
  }


  return <JcColumn flexGrow>
    <JcGrid cellRenderer={cellRenderer}
      className="nfl-bets-graph"
      columns={teams}
      columnWidth={_ => "100px"}
      rows={["TEAM", ...Array.from({ length: 17 }, (_, index) => index), "ODDS"]} />
    {!props.showSelection && <JcSelectionBar
      options={[
        {
          id: "sort",
          label: <JcRow spacing><div>Sort by</div> <JcLabel label={sortBy} /></JcRow>,
          onClick: () => {
            const sortByOptions: NFLSortByOptions[] = ["WINS", "DIV"]
            const currIndex = sortByOptions.indexOf(sortBy);
            setSortBy(sortByOptions[(currIndex + 1) % sortByOptions.length])
          }
        }, {
          id: "asc",
          label: <JcLabel label={asc ? "ASC" : "DESC"} />,
          onClick: () => setAsc(!asc),
        }, {
          id: "copy",
          label: <JcIcon icon="jc:copy" />,
          onClick: onCopy,
        }
      ]} />}
  </JcColumn>
}