import { fetchAndActivate, getValue, Value } from "firebase/remote-config";
import { remoteConfig } from "../../../../firebase";
import { NFLTeamLine } from "./NFLTeamLine";

export class NFLConfig {
    constructor(
        public readonly teamLines: NFLTeamLine[],
        public readonly year: number,
    ) { }

    public static async getConfig(): Promise<NFLConfig> {
        await fetchAndActivate(remoteConfig)

        const year: number = getValue(remoteConfig, "year").asNumber();
        const val: Value = getValue(remoteConfig, "teamLines" + year.toString());

        const jsonObject = JSON.parse(val.asString()) as any[];
        const teamInfos = jsonObject.map(object => NFLTeamLine.initFromJSON(object));

        return new NFLConfig(teamInfos, year);
    }
}