import { useEffect, useRef, useState, useMemo } from "react";
import { JcColumn } from "../../JcSDK/JcBox";
import { JcCommonProps, JcUtils } from "../../utils/JcUtils";
import { ChatMessageDTO } from "./dto/ChatMessageDTO";
import * as React from "react";
import { ChatMessageBlock } from "./ChatMessageBlock";
import { observer } from "mobx-react";
import { ChatBlockInfo, ChatUserProvider, ChatUtils } from "./ChatUtils";
import { useAppAuth } from "../JcAppAuthWrapper";
import { JcUserDTO } from "../users/dto/JcUserDTO";

export interface ChatMessageListProps extends JcCommonProps {
    readonly messages: ChatMessageDTO[];
    readonly displayInfo?: (userInfo: JcUserDTO) => React.ReactNode;
}

export const ChatMessageList = observer((props: ChatMessageListProps): React.ReactElement<ChatMessageListProps> => {
    const { messages, displayInfo, ...otherProps } = props;
    const commonProps = JcUtils.commonProps(otherProps, ["chat-message-list"]);

    const auth = useAppAuth();
    const [userProvider] = useState(() => new Map<string, ChatUserProvider>());
    const blocks = useMemo(() => ChatUtils.createBlocks(messages, auth.uid), [messages, auth.uid]);

    messages.forEach(message => {
        const userId = message.userId;
        if (!userProvider.has(userId)) {
            const provider = new ChatUserProvider(userId);
            userProvider.set(userId, provider);
        }
    });

    useEffect(() => {
        dummyRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages, userProvider]);

    const dummyRef = useRef<HTMLDivElement>(null);

    const blockRenderer = (block: ChatBlockInfo, i: number) => {
        const userInfo = userProvider.get(block.userId)?.userInfo ?? null;
        return <ChatMessageBlock key={block.timestamp + "-" + i}
            displayInfo={displayInfo}
            block={block}
            userInfo={userInfo} />
    };

    return <JcColumn {...commonProps} flexGrow>
        {blocks.map(blockRenderer)}
        <div ref={dummyRef} />
    </JcColumn>
});
