
import React, { useEffect, useMemo, useState } from 'react';
import { JcIcon } from '../../../JcSDK/JcIcon';
import { NFLBetter } from './models/NFLBetter';
import { NFLBetsGraphView } from './NFLBetsGraphView';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { NFLBetsUsersView } from './NFLBetsUsersView';
import { NFLBetsAccountView } from './NFLBetsAccountView';
import { useAccount } from '../GamesAuthWrapper';
import { NFLTeamInfo } from './models/NFLTeamInfo';
import { JcTab, JcTabs } from '../../../JcSDK/JcTabs';
import { ChatBoard } from '../../chat/ChatBoard';
import { JcLoadingIndicator } from '../../../JcSDK/JcLoadingIndicator';

type NFLTabView = "CHART" | "USERS" | "ACCOUNT" | "CHAT";


export interface NFLBetsViewProps extends JcCommonProps {
  teamInfos: NFLTeamInfo[]
}

export const NFLBetsView = (props: NFLBetsViewProps): React.ReactElement => {
  const currentUser = useAccount();

  const [selectedTab, setSelectedTab] = useState<NFLTabView>("CHART");
  const [nflBetters, setNFLBetters] = useState<NFLBetter[] | null>(null);

  useEffect(() => {
    NFLBetter.onBettersSnapshot(setNFLBetters);
  }, []);

  const tabs: JcTab<NFLTabView>[] = useMemo(() => [{
    id: "ACCOUNT",
    label: <JcIcon icon="jc:account" />,
    content: <NFLBetsAccountView teamInfos={props.teamInfos} nflBetter={currentUser} showLogoutButton />,
  }, {
    id: "CHART",
    label: "Chart",
    content: <NFLBetsGraphView teamInfos={props.teamInfos} nflBetter={currentUser} />,
  }, {
    id: "USERS",
    label: "Users",
    content: <NFLBetsUsersView teamInfos={props.teamInfos} nflBetters={nflBetters} />,
  }, {
    id: "CHAT",
    label: <JcIcon icon="jc:messages" />,
    content: <ChatBoard chatId="NFL-BETS" displayInfo={(userInfo) => <NFLChatBoardUserDisplay userId={userInfo.uid} teamInfos={props.teamInfos} />} />,
  }], [currentUser, props.teamInfos, nflBetters]);


  const commonProps = JcUtils.commonProps(props, ["nfl-bets-view"]);


  return <JcTabs {...commonProps}
    onTabChange={setSelectedTab}
    activeTab={selectedTab}
    tabs={tabs} />;
}

export interface NFLChatBoardUserDisplayProps {
  userId: string;
  teamInfos: NFLTeamInfo[]
}

export const NFLChatBoardUserDisplay = (props: NFLChatBoardUserDisplayProps): React.ReactElement<NFLChatBoardUserDisplayProps> => {
  const [nflBetter, setNflBetter] = useState<NFLBetter | null>(null);
  const { userId, teamInfos } = props;

  useEffect(() => {
    return NFLBetter.onBetterSnapshot(userId, setNflBetter);
  }, [userId])

  if (nflBetter == null) {
    return <JcLoadingIndicator />
  }

  return <NFLBetsAccountView
    className="nfl-bets-user-info"
    teamInfos={teamInfos}
    nflBetter={nflBetter} />
}