import React, { useEffect, useRef, useState } from 'react';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';


interface FadeInProps extends JcCommonProps {
    children: React.ReactNode;
}


export const JcFadeIn = (props: FadeInProps): React.ReactElement => {
    const [isVisible, setVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [observer] = useState(() => new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
    }))

    useEffect(() => {
        const current = ref.current;
        if (current) {
            observer.observe(ref.current)
        }

        return () => {
            if (current) {
                observer.unobserve(current)
            }
        };
    }, [observer]);

    const commonProps = JcUtils.commonProps(props, [
        "jc-fade-in",
        isVisible && "jc-fade-in-visible"
    ])

    return <div {...commonProps} ref={ref}>
        {props.children}
    </div>
}