import React from 'react';
import { JcColumn } from '../../../JcSDK/JcBox';
import { JcImage } from '../../../JcSDK/JcImage';
import { JcLabel } from '../../../JcSDK/JcLabel';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { NFLTeamInfo } from './models/NFLTeamInfo';


interface NFLBetsTeamLabelProps extends JcCommonProps {
  readonly teamInfo: NFLTeamInfo;
}


export const NFLBetsTeamLabel: React.FunctionComponent<NFLBetsTeamLabelProps> = (props: NFLBetsTeamLabelProps) => {

  const commonProps = JcUtils.commonProps(props, ["nfl-bets-team-label"])

  return <JcColumn {...commonProps} alignCenter>
    <JcColumn flexGrow justifyCenter alignCenter>
      <JcImage alt={props.teamInfo.nickname + " image"} size="medium" url={"/resources/images/nfl/" + props.teamInfo.nickname + ".png"} />
    </JcColumn>
    <JcLabel label={props.teamInfo.nickname} />
    <span>{props.teamInfo.wins.toString() + "-" + props.teamInfo.losses.toString() + "-" + props.teamInfo.ties.toString()}</span>
  </JcColumn>
}