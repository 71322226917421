import React from 'react';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';

export interface JcSeparatorProps extends JcCommonProps {
  readonly direction?: "horizontal" | "vertical";
}

export const JcSeparator = (props: JcSeparatorProps): React.ReactElement => {
  const direction = props.direction ?? "horizontal";
  const commonProps = JcUtils.commonProps(props, [
    "jc-separator",
    "jc-separator-" + direction,
  ]);
  return <hr {...commonProps} />;
}