
import React, { useMemo, useState } from 'react';
import { JcColumn } from '../../../JcSDK/JcBox';
import { JcIcon } from '../../../JcSDK/JcIcon';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { ChatBoard } from '../../chat/ChatBoard';
import { JcTab, JcTabs } from '../../../JcSDK/JcTabs';
import { PickEmChart } from './PickEmChart';

type NFLTabView = "CHART" | "USERS" | "ACCOUNT"

export const PickEmView = (props: JcCommonProps): React.ReactElement => {

  const [selectedTab, setSelectedTab] = useState<NFLTabView>("CHART");

  const tabs: JcTab<NFLTabView>[] = useMemo(() => [{
    id: "ACCOUNT",
    content: <ChatBoard chatId="PICK-EM" />,
    label: <JcIcon icon="jc:messages" />,
  }, {
    id: "CHART",
    content: <PickEmChart />,
    label: "Chart",
  }, {
    id: "USERS",
    content: <JcColumn flexGrow>Chill man</JcColumn>,
    label: "Users",
  }
  ], [])


  const commonProps = JcUtils.commonProps(props, ["pick-em-view"]);


  return <JcTabs {...commonProps}
    activeTab={selectedTab}
    onTabChange={setSelectedTab}
    tabs={tabs} />
}