
import React from 'react';
import { NFLBetsGraph } from './NFLBetsGraph';
import { JcColumn } from '../../../JcSDK/JcBox';
import { NFLBetter } from './models/NFLBetter';
import { NFLBetsPicker } from './NFLBetsPicker';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { NFLTeamInfo } from './models/NFLTeamInfo';
import { useAppAuth } from '../../JcAppAuthWrapper';


export interface NFLBetsGraphViewProps extends JcCommonProps {
  nflBetter: NFLBetter;
  teamInfos: NFLTeamInfo[];
}

export const NFLBetsGraphView = (props: NFLBetsGraphViewProps): React.ReactElement<NFLBetsGraphViewProps> => {

  const auth = useAppAuth();

  const renderGraph = () => {
    const bets = props.nflBetter.bets2024;
    if (bets) {
      return <NFLBetsGraph bets={bets} teamInfos={props.teamInfos} />
    }
    else if (props.nflBetter.uid !== auth.uid) {
      return <div>{"No bets found for user " + props.nflBetter.displayName}</div>;
    }
    else {
      return <NFLBetsPicker currentUser={props.nflBetter} teamInfos={props.teamInfos} />
    }
  };

  const commonProps = JcUtils.commonProps(props, ["nfl-bets-graph-view"])


  return <JcColumn {...commonProps} flexGrow>
    {renderGraph()}
  </JcColumn>;
}