import { useEffect, useState, } from "react";
import { JcColumn, JcRow } from "../../JcSDK/JcBox";
import { JcTextInput } from "../../JcSDK/JcTextInput";
import { JcIconButton } from "../../JcSDK/button/JcIconButton";
import { JcCommonProps } from "../../utils/JcUtils";
import { ChatMessageDTO } from "./dto/ChatMessageDTO";
import { JcFullPageLoadingIndicator } from "../../JcSDK/JcLoadingIndicator";
import { JcLabel } from "../../JcSDK/JcLabel";
import { ChatService } from "./service/ChatService";
import { useAppAuth } from "../JcAppAuthWrapper";
import * as React from "react";
import { ChatGroupDTO } from "./dto/ChatGroupDTO";
import { ChatMessageList } from "./ChatMessageList";
import { JcUserDTO } from "../users/dto/JcUserDTO";


export interface ChatBoardProps extends JcCommonProps {
    readonly chatId: string;
    readonly displayInfo?: (userInfo: JcUserDTO) => React.ReactNode;
}

export const ChatBoard = (props: ChatBoardProps): React.ReactElement<ChatBoardProps> => {
    const [textVal, setTextVal] = useState("");
    const [chatGroup, setChatGroup] = useState<ChatGroupDTO>();
    const [messages, setMessages] = useState<ChatMessageDTO[]>();

    useEffect(() => {
        if (props.chatId) {
            return ChatService.subscribeToMessages(props.chatId, setMessages)
        }
    }, [props.chatId]);

    useEffect(() => {
        return ChatService.subscribeToChatGroup(props.chatId, setChatGroup);
    }, [props.chatId])

    const onSendChat = () => {
        if (textVal !== "") {
            ChatService.sendMessage(props.chatId, auth.uid, textVal);
            setTextVal("");
        }
    }

    const auth = useAppAuth();

    return <JcColumn flexGrow className="chat-board">
        {chatGroup?.title && <JcRow className="chat-board-header" alignCenter spacing>
            <JcLabel label={chatGroup.title} />
        </JcRow>}
        {messages
            ? <ChatMessageList displayInfo={props.displayInfo} messages={messages} />
            : <JcFullPageLoadingIndicator label={"Loading messages ..."} />}
        <JcRow className="chat-input-row">
            <JcTextInput className="chat-input"
                value={textVal}
                placeholder={"Message"}
                onEnter={onSendChat}
                onChange={setTextVal} />
            <JcIconButton icon="jc:send" onClick={onSendChat} />
        </JcRow>
    </JcColumn>
}


