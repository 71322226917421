
import React, { useState } from 'react';
import { JcImage } from '../../JcSDK/JcImage';
import { JcColumn } from '../../JcSDK/JcBox';
import { JcTextInput } from '../../JcSDK/JcTextInput';
import { JcField } from '../../JcSDK/JcField';
import { JcFieldGroup } from '../../JcSDK/JcFieldGroup';
import { NFLBetter } from './nflBets/models/NFLBetter';
import { JcCheckbox } from '../../JcSDK/JcCheckbox';
import { useAppAuth } from '../JcAppAuthWrapper';
import { JcAppAuth } from '../service/JcAppAuth';
import { JcDialog } from '../../JcSDK/dialog/JcDialog';


export const GamesNewUserView = (): React.ReactElement => {
  const auth = useAppAuth()
  const [displayName, setDisplayName] = useState<string>(auth.displayName || "");

  const [agreed, setAgreed] = useState(false);
  let nflBetter: NFLBetter | null = null;

  if (displayName) {
    nflBetter = new NFLBetter(
      auth.uid,
      displayName,
      auth.photoURL || "",
      auth.email || "",
      null);
  }

  const onClick = () => {
    if (agreed) {
      return nflBetter?.commitDetails()
    }
  }


  return <JcColumn flexGrow alignCenter justifyCenter>
    <JcDialog hideCloseButton className="nfl-bets-new-user-form"
      onOk={onClick}
      okDisabled={!agreed}
      okButton={"Submit"}
      onClose={() => JcAppAuth.signOut()}
      closeButton="Sign out">
      <JcColumn spacing className="nfl-bets-sign-in-info">
        {auth.photoURL && <JcImage alt="profile picture" size="large" url={auth.photoURL} />}
        <JcFieldGroup style={{ width: "100%" }}>
          <JcField label="Display Name">
            <JcTextInput value={displayName} onChange={value => setDisplayName(value)} />
          </JcField>
        </JcFieldGroup>
        <JcCheckbox value={agreed} onClick={setAgreed} label={
          <div className="nfl-bets-agreements">
            {"By clicking this check box, you are agreeing that I (Jack) may use your email and google photo for use on this website. You are also agreeing that you won't sue for me for any reason."}
          </div>} />
      </JcColumn>
    </JcDialog>
  </JcColumn>;
}