import React from 'react';
import { JcCommonProps, JcUtils } from '../../utils/JcUtils';

interface ProjectGroupProps extends JcCommonProps {
    title: string;
    children: JSX.Element[] | JSX.Element;
}

export const ProjectGroup = (props: ProjectGroupProps): React.ReactElement => {
    const commonProps = JcUtils.commonProps(props, ["project-group"])
    return <section {...commonProps}>
        <h2 className="project-title" id={props.id + "-header"}><span>{props.title}</span></h2>
        <div>
            {props.children}
        </div>
    </section>;
}