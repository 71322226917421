import React from 'react';
import { JcLabel } from './JcLabel';


export interface JcFieldProps {
  readonly children: React.ReactNode;
  readonly label: string;
}


export const JcField: React.FunctionComponent<JcFieldProps> = (props: JcFieldProps) => {

  return <>
    <JcLabel label={props.label} />
    {props.children}
  </>;
}