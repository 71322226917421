import { JcUtils } from '../utils/JcUtils';
import React from 'react';
import { JcButton, JcButtonProps } from './button/JcButton';


export interface JcClickableTextProps extends Omit<JcButtonProps, "children" | "noBorder"> {
  readonly onClick: () => void;
  readonly selected?: boolean;
  readonly label: string;
}


export const JcClickableText: React.FunctionComponent<JcClickableTextProps> = (props: JcClickableTextProps) => {

  const commonProps = JcUtils.commonProps(props, ["jc-clickable-text"]);
  const { label, ...buttonProps } = JcUtils.otherProps(props);

  return <JcButton {...commonProps} {...buttonProps} noBorder>
    {label}
  </JcButton>
}