
import { CompleteFn, ErrorFn, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase';

export class JcAppAuth {

    public readonly uid: string;
    public readonly displayName: string | null;
    public readonly photoURL: string | null
    public readonly email: string | null

    constructor(
        uid: string,
        displayName: string | null,
        photoURL: string | null,
        email: string | null,
    ) {
        this.uid = uid;
        this.displayName = displayName;
        this.photoURL = photoURL;
        this.email = email;
    }

    public static signIn() {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    }

    public static signOut() {
        auth.signOut();
    }

    public static onAuthChange(onNextAuth: (auth: JcAppAuth | null) => void, error?: ErrorFn | undefined, completed?: CompleteFn | undefined) {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                return onNextAuth(null);
            }
            const jcAuth = new JcAppAuth(user.uid, user.displayName, user.photoURL, user.email)
            return onNextAuth(jcAuth);
        }, error, completed)
    }
}

