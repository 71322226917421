import React, { forwardRef } from 'react';
import { JcCommonProps, JcUtils } from '../../utils/JcUtils';


export interface JcButtonProps extends JcCommonProps {
  readonly onClick?: () => void;
  readonly selected?: boolean;
  readonly disabled?: boolean;
  readonly children: React.ReactNode;
  readonly noBorder?: boolean;
}


export const JcButton = forwardRef((props: JcButtonProps, ref: React.ForwardedRef<HTMLButtonElement>): React.ReactElement => {

  const onClick = props.disabled ? undefined : props.onClick;

  const commonProps = JcUtils.commonProps(props, [
    props.selected && "jc-selected",
    onClick && "jc-clickable",
    props.disabled && "jc-disabled",
    props.noBorder && "jc-no-border-button",
    "jc-button",
  ]);

  return <button ref={ref} {...commonProps} onClick={onClick}>
    {props.children}
  </button>
})