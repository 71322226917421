import { action, makeObservable, observable } from "mobx";
import { JcUserDTO } from "../users/dto/JcUserDTO";
import { Timestamp, Unsubscribe } from "firebase/firestore";
import { JcUserService } from "../users/service/JcUserService";
import { ChatMessageDTO } from "./dto/ChatMessageDTO";
import { JcUtils } from "../../utils/JcUtils";


export class ChatUserProvider {

    @observable
    private _userInfo: JcUserDTO | null;

    public readonly unsubscribe: Unsubscribe;

    constructor(id: string) {
        makeObservable(this);
        this._userInfo = null;
        this.unsubscribe = JcUserService.subscribeToUser(id, this.setUser);
    }

    @action.bound
    private setUser(user: JcUserDTO) {
        this._userInfo = user;
    }

    public get userInfo(): JcUserDTO | null {
        return this._userInfo;
    }
}

export interface ChatBlockInfo {
    messages: ChatMessageDTO[];
    sentByCurrentUser: boolean;
    hasTimePassed: boolean;
    userId: string;
    timestamp: number;
}

export namespace ChatUtils {
    const TIME_PASSED_DURATION = 1000 * 60 * 60;

    const timeHasPassed = (message: ChatMessageDTO, block: ChatBlockInfo): boolean => {
        const prevMessage = JcUtils.lastOrNull(block.messages);
        if (prevMessage == null) {
            return true;
        }

        return (message.timestamp - prevMessage.timestamp) > TIME_PASSED_DURATION;
    }

    const nextMessageIsSameBlock = (messages: ChatMessageDTO[], block: ChatBlockInfo): boolean => {
        const message = JcUtils.lastOrNull(messages);
        if (message == null) {
            return false;
        }

        return message.userId === block.userId && !timeHasPassed(message, block);
    }

    export const createBlocks = (messages: ChatMessageDTO[], currUserId: string): ChatBlockInfo[] => {
        const allBlocks: ChatBlockInfo[] = [];
        const messagesLeft = [...messages].reverse();

        while (messagesLeft.length > 0) {
            const firstMessage = messagesLeft.pop()!;
            const prevBlock = JcUtils.lastOrNull(allBlocks);
            const newBlock: ChatBlockInfo = {
                messages: [firstMessage],
                sentByCurrentUser: firstMessage.userId === currUserId,
                hasTimePassed: prevBlock == null || timeHasPassed(firstMessage, prevBlock),
                userId: firstMessage.userId,
                timestamp: firstMessage.timestamp,
            }

            while (nextMessageIsSameBlock(messagesLeft, newBlock)) {
                const nextMessage = messagesLeft.pop()!;
                newBlock.messages.push(nextMessage);
            }

            allBlocks.push(newBlock);
        }

        return allBlocks;
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    const spreadDate = (date: Date): { month: number, day: number, year: number, hour: number, minute: number, dayOfWeek: number } => {
        return {
            month: date.getMonth(),
            day: date.getDate(),
            year: date.getFullYear(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            dayOfWeek: date.getDay(),
        }
    }

    const isSameDay = (dateA: Date, dateB: Date): boolean => {
        const a = spreadDate(dateA);
        const b = spreadDate(dateB);
        return a.day === b.day && a.month === b.month && a.year === b.year;
    }

    const sentNDaysAgo = (date: Date, n: number): boolean => {
        const earlierDay = new Date();
        earlierDay.setDate(earlierDay.getDate() - n);
        return isSameDay(date, earlierDay);
    }

    const sentToday = (date: Date): boolean => {
        return sentNDaysAgo(date, 0);
    }

    const sentYesterday = (date: Date): boolean => {
        return sentNDaysAgo(date, 1);
    }

    const dateCeiling = (date: Date): Date => {
        const { day, month, year } = spreadDate(date);
        return new Date(year, month, day + 1);
    }

    const sentPassedWeek = (date: Date): boolean => {
        const oneWeekAgo = dateCeiling(new Date());
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        return date.getTime() > oneWeekAgo.getTime();
    }

    const sentPassedYear = (date: Date): boolean => {
        const oneYearAgo = dateCeiling(new Date());
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        return date.getTime() > oneYearAgo.getTime();
    }

    const formatTime = (hour: number, minute: number): string => {
        const hourText = ((hour % 12) || 12).toString();
        const minuteText = minute < 10
            ? "0" + minute.toString()
            : minute.toString();

        const amPm = hour < 12 ? "AM" : "PM"
        return `${hourText}:${minuteText} ${amPm}`;
    }

    export const formatTimestamp = (millis: number): string => {
        const date = Timestamp.fromMillis(millis).toDate();
        const { month, day, year, minute, hour, dayOfWeek } = spreadDate(date);

        const monthText = months[month];
        const dayText = day.toString();
        const yearText = year.toString();

        const timeText = formatTime(hour, minute);
        if (sentToday(date)) {
            return `Today ${timeText}`;
        }
        else if (sentYesterday(date)) {
            return `Yesterday ${timeText}`
        }
        else if (sentPassedWeek(date)) {
            const weekday = days[dayOfWeek];
            return `${weekday} ${timeText}`
        }
        else if (sentPassedYear(date)) {
            const weekdayAbbr = days[dayOfWeek].substring(0, 3);
            return `${weekdayAbbr}, ${monthText} ${dayText} at ${timeText}`
        }
        else {
            return `${monthText} ${dayText}, ${yearText} at ${timeText}`;
        }
    }
}
