import { JcCommonProps, JcUtils } from "../utils/JcUtils";



export interface JcGridProps<RowT, ColT> extends JcCommonProps {
  readonly topRowRenderer?: () => React.ReactNode;
  readonly rows: RowT[];
  readonly columns: ColT[];
  readonly cellRenderer: (row: RowT, col: ColT, lastChild: boolean) => React.ReactNode;
  readonly columnWidth?: (col: ColT) => string
}

export const JcGrid = <RowT, ColT>(props: JcGridProps<RowT, ColT>): React.ReactElement<JcGridProps<RowT, ColT>> => {


  const { style, ...commonProps } = JcUtils.commonProps(props, ["jc-grid"]);
  const updatedStyle: React.CSSProperties = {
    gridTemplateColumns: props.columnWidth
      ? props.columns.map(col => props.columnWidth!(col)).join(" ")
      : `repeat(${props.columns.length}, auto)`, ...style
  }

  return <div {...commonProps} style={updatedStyle} >
    {props.topRowRenderer && props.topRowRenderer()}
    {props.rows.map((row, i) => props.columns.map((col, j) => props.cellRenderer(row, col, i === props.rows.length - 1 || j === props.columns.length - 1)))}
  </div>
}