import { NFLBet } from "./NFLBet";
import { doc, setDoc, DocumentSnapshot, SnapshotOptions, onSnapshot, updateDoc, query, collection } from "firebase/firestore";
import { firestore as db } from "../../../../firebase";
import { NFLTeamBetInfo } from "./NFLTeamBetInfo";
import { NFLTeamInfo } from "./NFLTeamInfo";
import { ChatService } from "../../../chat/service/ChatService";


export interface NFLBetterI {
    readonly uid: string,
    readonly displayName: string,
    readonly photoURL: string,
    readonly email: string,
    readonly bets2024: {
        teamID: number,
        type: "OVER" | "UNDER"
    }[] | null,
}

export class NFLBetter implements NFLBetterI {

    constructor(
        public readonly uid: string,
        public readonly displayName: string,
        public readonly photoURL: string,
        public readonly email: string,
        public readonly bets2024: NFLBet[] | null,
    ) { }

    public betChart(teamInfos: NFLTeamInfo[]): NFLTeamBetInfo[] | null {
        if (this.bets2024) {
            const idToBet = new Map<number, NFLBet>();
            this.bets2024.forEach((bet) => idToBet.set(bet.teamID, bet))
            return teamInfos.map(team => new NFLTeamBetInfo(team.name,
                team.nickname,
                team.teamID,
                team.wins,
                team.losses,
                team.ties,
                team.line,
                idToBet.get(team.teamID)!.type,
                team.overOdds,
                team.underOdds,
                team.divisionID))
        }
        return null;
    }

    public betInfo(teamInfo: NFLTeamInfo): NFLTeamBetInfo | null {
        const bet = this.bets2024?.find(bet => teamInfo.teamID === bet.teamID)
        if (bet) {
            return new NFLTeamBetInfo(teamInfo.name,
                teamInfo.nickname,
                teamInfo.teamID,
                teamInfo.wins,
                teamInfo.losses,
                teamInfo.ties,
                teamInfo.line,
                bet.type,
                teamInfo.overOdds,
                teamInfo.underOdds,
                teamInfo.divisionID)
        }
        return null;
    }

    public numberCorrect(teamInfos: NFLTeamInfo[]): number {
        return this.betChart(teamInfos)?.reduce((prevVal, currVal) => prevVal + (currVal.clinchedWin ? 1 : 0), 0) ?? 0
    }

    public numberIncorrect(teamInfos: NFLTeamInfo[]): number {
        return this.betChart(teamInfos)?.reduce((prevVal, currVal) => prevVal + (currVal.clinchedLoss ? 1 : 0), 0) ?? 0
    }

    public numberWaiting(teamInfos: NFLTeamInfo[]): number {
        return this.betChart(teamInfos)?.reduce((prevVal, currVal) => prevVal + ((currVal.clinchedWin || currVal.clinchedLoss) ? 0 : 1), 0) ?? 0
    }

    public calcEarnings(teamInfos: NFLTeamInfo[]): number {
        return this.betChart(teamInfos)?.reduce((prevVal, currVal) => prevVal + currVal.calcEarnings(), 0) ?? 0
    }

    public moneyAwaiting(teamInfos: NFLTeamInfo[]): number {
        return this.betChart(teamInfos)?.reduce((prevVal, currVal) => prevVal + ((currVal.clinchedWin || currVal.clinchedLoss) ? 0 : NFLTeamBetInfo.BET_AMOUNT), 0) ?? 0
    }

    public static onBetterSnapshot(uid: string, onBetter: (better: NFLBetter | null) => void) {
        return onSnapshot(doc(db, "users", uid).withConverter(NFLBetter.converter), doc => {
            onBetter(doc.data() ?? null);
        });
    }

    public async commitDetails(): Promise<void> {
        console.log("SETTING USER");
        await ChatService.addMemberToChat(this.uid, "PICK-EM");
        return setDoc(doc(db, "users", this.uid).withConverter(NFLBetter.converter), this);
    }

    public async setBets(bets: NFLBet[]): Promise<void> {
        console.log("SETTING BETS");
        return updateDoc(doc(db, "users", this.uid), { bets2024: bets.map(bet => bet.toJSON()) });
    }

    public static async onBettersSnapshot(onBetters: (betters: NFLBetter[] | null) => void) {
        return onSnapshot(query(collection(db, "users")), doc => {
            const betters = doc.docs.map(d => this.converter.fromFirestore(d)!)
            onBetters(betters);
        });
    }


    public static converter = {
        toFirestore: (nflBetter: NFLBetter): NFLBetterI => {
            return {
                uid: nflBetter.uid,
                displayName: nflBetter.displayName,
                photoURL: nflBetter.photoURL,
                email: nflBetter.email,
                bets2024: nflBetter.bets2024 ? nflBetter.bets2024.map(bet => ({
                    teamID: bet.teamID,
                    type: bet.type,
                })) : null,
            }
        },
        fromFirestore: (snapshot: DocumentSnapshot, options?: SnapshotOptions) => {
            const data = snapshot.data(options) as NFLBetterI | undefined;
            if (data) {
                return new NFLBetter(
                    data.uid,
                    data.displayName,
                    data.photoURL,
                    data.email,
                    data.bets2024?.map((obj: any) => NFLBet.initFromJSON(obj)) ?? null,
                );
            }
            return null;
        }
    }
}

