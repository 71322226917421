// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC45Hox_rkTI5bzUNgJRuIHrk41pUOxeNU",
    authDomain: "jcallard-4c495.firebaseapp.com",
    projectId: "jcallard-4c495",
    storageBucket: "jcallard-4c495.appspot.com",
    messagingSenderId: "477917152172",
    appId: "1:477917152172:web:6c170bb315abf3cd0ff009",
    measurementId: "G-J2P4779CBH",
    databaseURL: "https://jcallard-4c495-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const remoteConfig = getRemoteConfig(app);
export const firestore = getFirestore(app);
export const storage = getStorage();
export const database = getDatabase();

remoteConfig.settings.minimumFetchIntervalMillis = 36000;


