import React from "react";
import { JcPage } from "../../../JcSDK/JcPage";
import { JcAppTitleBar } from "../../JcAppTitleBar";
import { GamesAuthWrapper } from "../GamesAuthWrapper";
import { PickEmView } from "./PickEmView";



export const PickEmPage = (): React.ReactElement => {

    return <JcPage className="nfl-bets-page jc-page-with-title-bar">
        <JcAppTitleBar location="PICK-EM" />
        <GamesAuthWrapper>
            <PickEmView />
        </GamesAuthWrapper>
    </JcPage>;
}