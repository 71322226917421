import { JcColumn, JcRow } from "../../../JcSDK/JcBox";
import { JcImage } from "../../../JcSDK/JcImage";
import { JcLabel } from "../../../JcSDK/JcLabel";
import { JcButton, JcButtonProps } from "../../../JcSDK/button/JcButton";
import { JcCommonProps, JcUtils } from "../../../utils/JcUtils";
import { PickEmMatchUp } from "./models/PickEmMatchUp";
import { PickEmTeamInfo } from "./models/PickEmTeamInfo";

export interface PickEmMatchUpTileProps extends JcCommonProps {
    readonly matchUp: PickEmMatchUp;
    readonly selectedTeamId?: number;
    readonly onTeamSelection: (teamId: number) => void;
}

export const PickEmMatchUpTile = (props: PickEmMatchUpTileProps): React.ReactElement => {
    const commonProps = JcUtils.commonProps(props, ["pick-em-match-up-tile"]);
    const renderTime = (): React.ReactNode => {
        if (props.matchUp.status.final) {
            return "Final"
        }
    }

    const renderTeamRow = (teamInfo: PickEmTeamInfo, score: number) => {
        return <PickEmMatchUpTeamRow selected={props.selectedTeamId === teamInfo.id}
            onClick={() => props.onTeamSelection(teamInfo.id)}
            teamInfo={teamInfo}
            score={score} />
    }

    return <JcColumn {...commonProps}>
        {renderTime()}
        <JcColumn className="pick-em-match-up-tile-teams">
            {renderTeamRow(props.matchUp.awayInfo, props.matchUp.status.awayScore)}
            {renderTeamRow(props.matchUp.homeInfo, props.matchUp.status.homeScore)}
        </JcColumn>
    </JcColumn>
}


export interface PickEmMatchUpTeamRowProps extends Omit<JcButtonProps, "children"> {
    teamInfo: PickEmTeamInfo;
    score: number;
}

export const PickEmMatchUpTeamRow = (props: PickEmMatchUpTeamRowProps): React.ReactElement => {
    const { teamInfo, score, className, ...buttonProps } = props;
    const rowClassName = JcUtils.classNameString([className, "pick-em-match-up-team-row"]);
    return <JcButton className={rowClassName} {...buttonProps}>
        <JcRow spacing alignCenter>
            <JcImage size="medium" alt={props.teamInfo.name + " logo"}
                url={props.teamInfo.logo} />
            <JcLabel label={props.teamInfo.nickname} />
            <div>{props.teamInfo.record.recordString()}</div>
        </JcRow>
        <div>{props.score}</div>
    </JcButton>
}