import * as React from "react";
import { JcDropdown } from "../../../JcSDK/button/JcDropdown";
import { JcColumn, JcRow } from "../../../JcSDK/JcBox";
import { JcField } from "../../../JcSDK/JcField";
import { JcFieldGroup } from "../../../JcSDK/JcFieldGroup";
import { JcFullPageLoadingIndicator } from "../../../JcSDK/JcLoadingIndicator";
import { PickEmMatchUp } from "./models/PickEmMatchUp";
import { PickEmMatchUpsView } from "./PickEmMatchUpsView";
import { PickEmService } from "./service/PickEmService";
import { useEffect, useState } from "react";

export interface PickEmChartProps { }

export const PickEmChart = (props: PickEmChartProps): React.ReactElement<PickEmChartProps> => {
    const [week, setWeek] = useState(4);

    const [matchUps, setMatchUps] = useState<PickEmMatchUp[] | null>(null);

    useEffect(() => {
        PickEmService.getMatchUps(week).then(setMatchUps);
    }, [week]);

    return <JcColumn className="pick-em-week-match-ups" spacing flexGrow>
        <JcFieldGroup>
            <JcField label="Week:">
                <JcRow>
                    <JcDropdown value={week}
                        keyFunction={wk => wk.toString()}
                        options={Array.from({ length: 18 }, (_, index) => (index + 1))}
                        onChange={newWeek => {
                            setMatchUps(null);
                            setWeek(newWeek)
                        }} />
                </JcRow>
            </JcField>
        </JcFieldGroup>
        {matchUps
            ? <PickEmMatchUpsView matchUps={matchUps} />
            : <JcFullPageLoadingIndicator />}
    </JcColumn>
}