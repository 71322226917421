import * as React from "react";
import { JcCommonProps, JcUtils } from "../utils/JcUtils";
import { Allotment } from "allotment";

export interface JcSplitPaneProps extends Omit<JcCommonProps, "style"> {
    readonly initialPercent?: number;
    readonly children: React.ReactNode;
    readonly vertical: boolean;
    readonly defaultSizes?: number[];
}

export const JcSplitPane = (props: JcSplitPaneProps): React.ReactElement<JcSplitPaneProps> => {
    const commonProps = JcUtils.commonProps(props, ["jc-split-pane"]);
    return <Allotment {...commonProps}
        vertical={props.vertical}
        defaultSizes={props.defaultSizes}>
        {props.children}
    </Allotment>
}