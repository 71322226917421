import React from 'react';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';


export interface JcTextInputProps extends JcCommonProps {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly onEnter?: () => void;
  readonly placeholder?: string;
}


export const JcTextInput: React.FunctionComponent<JcTextInputProps> = (props: JcTextInputProps) => {

  const commonProps = JcUtils.commonProps(props, ["jc-input"]);

  return <input {...commonProps}
    value={props.value}
    placeholder={props.placeholder}
    onKeyDown={(e) => {
      if (e.key === "Enter" && props.onEnter != null) {
        props.onEnter();
      }
    }}
    onChange={e => props.onChange(e.target.value)} />;
}