import { JcCommonProps, JcUtils } from '../../utils/JcUtils';
import { JcButton } from './JcButton';

import React from 'react';
import { JcRow } from '../JcBox';

export interface JcSelectionOption<T> {
  id: T;
  label: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export interface JcSelectionBarProps<T = string> extends JcCommonProps {
  readonly selectedID?: T;
  readonly options: JcSelectionOption<T>[];
  readonly onClick?: (id: T) => void;
}


export const JcSelectionBar = <T,>(props: JcSelectionBarProps<T>) => {

  const commonProps = JcUtils.commonProps(props, ["jc-button-bar"]);

  return <JcRow {...commonProps}>
    {props.options.map(option => {
      const onClick = option.onClick || (props.onClick && (() => props.onClick && props.onClick(option.id)));
      return <JcButton key={option.id + "-key"}
        className="jc-button-bar-button"
        noBorder
        disabled={option.disabled}
        selected={option.id === props.selectedID}
        onClick={onClick}>{option.label}
      </JcButton>
    })}
  </JcRow>
}