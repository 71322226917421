import React from "react";
import { JcCommonProps, JcUtils } from "../utils/JcUtils";


export interface JcPageProps extends JcCommonProps {
    children: React.ReactNode;
}


export const JcPage = (props: JcPageProps): React.ReactElement => {

    const commonProps = JcUtils.commonProps(props, ["jc-page"]);

    return <div {...commonProps}>
        {props.children}
    </div>
}