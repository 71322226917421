import { JcHomePage } from './home/HomePage';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import React from 'react';
import { NFLBetsPage } from './games/nflBets/NFLBetsPage';
import { JcAppPageNotFound } from './JcNotFoundPage';
import { ProjectPage } from './projects/ProjectPage';
import { PickEmPage } from './games/pick-em/PickEmPage';
import { ChatPage } from './chat/ChatPage';
import { JcColumn } from '../JcSDK/JcBox';


export const JcApp = (): React.ReactElement => {

    return <JcColumn className="jc-app">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Outlet />}>
                    <Route index element={<JcHomePage />} />
                    <Route path="/bets" element={<NFLBetsPage />} />
                    <Route path="/pick-em" element={<PickEmPage />} />
                    <Route path="/chats" element={<ChatPage />} />
                    <Route path="/projects" element={<ProjectPage />} />
                    <Route path="contact" element={<JcHomePage />} />
                    <Route path="*" element={<JcAppPageNotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </JcColumn>;
}
