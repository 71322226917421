export class PickEmStatus {
    public readonly homeScore: number;
    public readonly awayScore: number;
    public readonly final: boolean;
    public readonly period: number;
    public readonly clock: string;

    constructor(homeScore: number, awayScore: number, final: boolean, period: number, clock: string) {
        this.homeScore = homeScore;
        this.awayScore = awayScore;
        this.final = final;
        this.period = period;
        this.clock = clock;
    }
}