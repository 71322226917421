import { Reducer, useEffect, useReducer, useState } from "react";
import { JcColumn, JcRow } from "../../JcSDK/JcBox";
import { JcKeyFunction } from "../../utils/JcUtils";
import { JcFullPageLoadingIndicator } from "../../JcSDK/JcLoadingIndicator";
import { ChatService } from "./service/ChatService";
import { useAppAuth } from "../JcAppAuthWrapper";
import * as React from "react";
import { ChatGroup } from "./ChatGroup";
import { JcSplitPane } from "../../JcSDK/JcSplitPane";
import { JcList } from "../../JcSDK/JcList";
import { JcDialog } from "../../JcSDK/dialog/JcDialog";
import { JcPopupButton } from "../../JcSDK/button/JcPopupButton";
import { JcUserDTO } from "../users/dto/JcUserDTO";
import { JcUserService } from "../users/service/JcUserService";
import { JcCheckbox } from "../../JcSDK/JcCheckbox";
import { JcImage } from "../../JcSDK/JcImage";
import { JcLabel } from "../../JcSDK/JcLabel";
import { JcTextInput } from "../../JcSDK/JcTextInput";
import { ChatBoard } from "./ChatBoard";


export interface ChatMessengerProps { };

const CHAT_GROUP_KEY_FUNCTION: JcKeyFunction<string> = chatId => chatId;
const CHAT_GROUP_ITEM_RENDERER = (chatId: string) => <ChatGroup chatId={chatId} />;

export const ChatMessenger = (props: ChatMessengerProps): React.ReactElement<ChatMessengerProps> => {
    const [chatIds, setChatIds] = useState<string[]>();
    const [selectedChatId, setSelectedChatId] = useState<string>();
    const auth = useAppAuth();

    useEffect(() => {
        return ChatService.subscribeToChats(auth.uid, setChatIds);
    }, [auth.uid])

    useEffect(() => {
        if (selectedChatId == null && chatIds != null && chatIds.length > 0) {
            setSelectedChatId(chatIds[0]);
        }
    }, [chatIds, selectedChatId])

    const [isNewChatDialogOpen, setNewChatDialogOpen] = useState(false);

    return <JcSplitPane className="chat-chatter" defaultSizes={[35, 65]} vertical={false}>
        {chatIds
            ? <JcColumn>
                <JcRow className="chat-chatter-toolbar">
                    <JcPopupButton open={isNewChatDialogOpen}
                        setOpen={setNewChatDialogOpen}
                        popupContent={<ChatChooseMembersDialog
                            onClose={() => setNewChatDialogOpen(false)}
                            onOk={(userIds, initialMessage, groupTitle) => {
                                ChatService.createChat(auth.uid, userIds, initialMessage, groupTitle);
                                setNewChatDialogOpen(false);
                            }} />}
                        trigger="jc:write" />
                </JcRow>
                <JcList items={chatIds}
                    keyFunction={CHAT_GROUP_KEY_FUNCTION}
                    className="chat-groups-list"
                    selection={selectedChatId}
                    noBackground
                    onSelection={setSelectedChatId}
                    itemRenderer={CHAT_GROUP_ITEM_RENDERER} />
            </JcColumn>
            : <JcFullPageLoadingIndicator label={"Loading messages ..."} />}
        {selectedChatId
            ? <ChatBoard chatId={selectedChatId} />
            : <JcColumn justifyCenter alignCenter className="chat-chatter-select-chat" >
                {"Select chat"}
            </JcColumn>}
    </JcSplitPane>
}

export interface ChatChooseMembersDialogProps {
    onClose: () => void;
    onOk: (userIds: string[], initialMessage: string, groupTitle: string) => void;
}

const CHAT_CHOOSE_MEMBERS_KEY_FUNCTION: JcKeyFunction<JcUserDTO> = user => user.uid;

export const ChatChooseMembersDialog = (props: ChatChooseMembersDialogProps): React.ReactElement<ChatChooseMembersDialogProps> => {
    const [selectedUserIds, toggleMember] = useReducer<Reducer<string[], string>>((prevMembers, newMember) => {
        if (prevMembers.includes(newMember)) {
            return prevMembers.filter(id => id !== newMember);
        }
        return [...prevMembers, newMember]
    }, []);

    const [users, setUsers] = useState<JcUserDTO[]>();
    const auth = useAppAuth();

    const [groupTitle, setGroupTitle] = useState("");

    useEffect(() => {
        return JcUserService.subscribeToUsers(setUsers)
    }, [])

    return <JcDialog
        title={"Choose members"}
        onOk={() => props.onOk(selectedUserIds, "Hey now baby", groupTitle)}
        onClose={props.onClose}>
        <JcColumn>
            <JcTextInput value={groupTitle} onChange={setGroupTitle} placeholder="Group title" />
            {users != null
                ? <JcList
                    keyFunction={CHAT_CHOOSE_MEMBERS_KEY_FUNCTION}
                    itemRenderer={user => {
                        return <JcCheckbox label={<JcRow alignCenter flexGrow spacing>
                            <JcImage
                                alt="profile picture"
                                className="nfl-bets-user-label-image"
                                size="medium"
                                url={user.photoURL} />
                            <JcLabel label={user.displayName} />
                        </JcRow>}
                            value={selectedUserIds.includes(user.uid)}
                            onClick={() => toggleMember(user.uid)} />
                    }}
                    items={users.filter(userInfo => userInfo.uid !== auth.uid)} />
                : <JcFullPageLoadingIndicator label="Loading users ..." />}
        </JcColumn>
    </JcDialog>
}


