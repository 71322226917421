
import React from 'react';
import { JcButton } from '../../../JcSDK/button/JcButton';
import { JcImage } from '../../../JcSDK/JcImage';
import { JcLabel } from '../../../JcSDK/JcLabel';
import { JcColumn } from '../../../JcSDK/JcBox';
import { NFLBetter } from './models/NFLBetter';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { JcFieldGroup } from '../../../JcSDK/JcFieldGroup';
import { JcField } from '../../../JcSDK/JcField';
import { NFLTeamBetInfo } from './models/NFLTeamBetInfo';
import { NFLTeamInfo } from './models/NFLTeamInfo';
import { JcAppAuth } from '../../service/JcAppAuth';

export interface NFLBetsAccountViewProps extends JcCommonProps {
  nflBetter: NFLBetter;
  teamInfos: NFLTeamInfo[];
  showLogoutButton?: boolean;
}

export const NFLBetsAccountView = (props: NFLBetsAccountViewProps): React.ReactElement<NFLBetsAccountViewProps> => {

  const commonProps = JcUtils.commonProps(props);

  const teamInfos = props.teamInfos;
  const totalBet = (props.nflBetter.bets2024?.length ?? 0) * NFLTeamBetInfo.BET_AMOUNT;

  const numberCorrect = props.nflBetter.numberCorrect(teamInfos);
  const numberIncorrect = props.nflBetter.numberIncorrect(teamInfos);
  const numberWaiting = props.nflBetter.numberWaiting(teamInfos);
  const totalEarning = props.nflBetter.calcEarnings(teamInfos);
  const totalWaitingOn = props.nflBetter.moneyAwaiting(teamInfos);
  const netProfit = totalEarning - totalBet + totalWaitingOn;

  return <JcColumn {...commonProps} flexGrow justifyCenter alignCenter spacing>
    <JcColumn alignCenter spacing>
      <JcImage alt="profile picture" url={props.nflBetter.photoURL!} size="large" />
      <JcLabel label={props.nflBetter.displayName || ""} />
      <JcFieldGroup>
        <JcField label={"Total bet"}>
          {totalBet.toFixed(2)}
        </JcField>
        <JcField label={"Win-loss-waiting"}>
          {`${numberCorrect}-${numberIncorrect}-${numberWaiting}`}
        </JcField>
        <JcField label={"Total won"}>
          {totalEarning.toFixed(2)}
        </JcField>
        <JcField label="Net profit">
          {netProfit.toFixed(2)}
        </JcField>
        <JcField label="Amount waiting on">
          {totalWaitingOn.toFixed(2)}
        </JcField>
      </JcFieldGroup>
      {props.showLogoutButton && <JcButton onClick={() => JcAppAuth.signOut()}>Sign out</JcButton>}
    </JcColumn>
  </JcColumn>;
}