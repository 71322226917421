import React from "react";
import { JcCommonProps, JcKeyFunction, JcUtils } from "../utils/JcUtils";
import { JcButton } from "./button/JcButton";
import { JcRow } from "./JcBox";

export interface JcListProps<T> extends JcCommonProps {
    readonly items: Readonly<T[]>;
    readonly itemRenderer?: (item: T) => React.ReactNode;
    readonly selection?: T;
    readonly onSelection?: (item: T) => void;
    readonly keyFunction: JcKeyFunction<T>;
    readonly noBorder?: boolean;
    readonly noBackground?: boolean;
}

export const JC_LIST_DEFAULT_ITEM_RENDERER = <T,>(item: T): React.ReactNode => item as any;

export const JcList = <T,>(props: JcListProps<T>) => {
    const commonProps = JcUtils.commonProps(props, [
        "jc-list",
        !props.noBackground && "jc-app-white-background",
        props.noBorder && "jc-list-no-border",
    ]);

    const listItemRenderer = (item: T) => {
        const key = props.keyFunction(item);
        const onSelection = props.onSelection;
        const itemRenderer = props.itemRenderer ?? JC_LIST_DEFAULT_ITEM_RENDERER;
        const className = "jc-list-item";
        if (onSelection) {
            return <JcButton key={key}
                noBorder
                className={className}
                selected={props.selection === item}
                onClick={() => onSelection(item)}>
                {itemRenderer(item)}
            </JcButton>
        }
        else {
            return <JcRow key={key} className={className}>
                {itemRenderer(item)}
            </JcRow>
        }
    }

    return <ul {...commonProps}>
        {props.items.map(listItemRenderer)}
    </ul>
}