import React from 'react';
import { JcCommonProps, JcUtils } from '../utils/JcUtils';
import { JcRow } from './JcBox';
import { JcIcon } from './JcIcon';


export interface JcNoticeProps extends JcCommonProps {
  readonly message: string;
}


export const JcNotice: React.FunctionComponent<JcNoticeProps> = (props: JcNoticeProps) => {

  const commonProps = JcUtils.commonProps(props, ["jc-notice"]);

  return <JcRow {...commonProps} flexGrow spacing alignCenter>
    <JcIcon size="extra-small" icon="jc:info" />
    {props.message}
  </JcRow>
}