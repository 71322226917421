
import React from 'react';
import { JcFullPageLoadingIndicator } from '../../../JcSDK/JcLoadingIndicator';
import { JcColumn } from '../../../JcSDK/JcBox';
import { NFLBetter } from './models/NFLBetter';
import { JcCommonProps, JcUtils } from '../../../utils/JcUtils';
import { NFLBetsUsersPicksView } from './NFLBetsUsersPicksView';
import { NFLTeamInfo } from './models/NFLTeamInfo';

export interface NFLBetsUsersViewProps extends JcCommonProps {
  readonly teamInfos: NFLTeamInfo[];
  readonly nflBetters: NFLBetter[] | null;
}


export const NFLBetsUsersView = (props: NFLBetsUsersViewProps): React.ReactElement<NFLBetsUsersViewProps> => {


  const renderContent = () => {
    const betters = props.nflBetters
    if (betters) {
      return <NFLBetsUsersPicksView teamInfos={props.teamInfos} nflBetters={betters} />
    }
    return <JcFullPageLoadingIndicator label="Loading users" />;
  };

  const commonProps = JcUtils.commonProps(props, ["nfl-bets-users-view"])

  return <JcColumn {...commonProps} flexGrow spacing>
    {renderContent()}
  </JcColumn>;
}