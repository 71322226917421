import { onSnapshot, query, collection, QuerySnapshot, doc, DocumentSnapshot } from "firebase/firestore";
import { JcUserDTO } from "../dto/JcUserDTO";
import { firestore } from "../../../firebase";

export class JcUserService {

    public static subscribeToUsers(onUsers: (betters: JcUserDTO[]) => void) {
        return onSnapshot(query(collection(firestore, "users")), (doc: QuerySnapshot) => {
            onUsers(doc.docs.map(d => d.data() as JcUserDTO))
        });
    }

    public static subscribeToUser(userId: string, onUser: (user: JcUserDTO) => void) {
        return onSnapshot(doc(firestore, "users", userId), (doc: DocumentSnapshot) => {
            onUser(doc.data() as JcUserDTO);
        });
    }
}