import React from "react";
import { JcCommonProps, JcKeyFunction, JcUtils } from "../utils/JcUtils";
import { JcColumn } from "../JcSDK/JcBox";
import { useNavigate } from "react-router-dom";
import { Drawer } from "@mui/material";
import { JcList } from "../JcSDK/JcList";
import { JcAppLocation, getLocationRoute, getLocationLabel, JcAppTitleText, MAIN_LOCATIONS } from "./JcAppTitleBar";
import { JcSeparator } from "../JcSDK/JcSeparator";


export interface JcAppDrawerProps extends JcCommonProps {
    readonly location: JcAppLocation;
    readonly open: boolean;
    readonly onClose: () => void;
}

const APP_DRAWER_KEY_FUNCTION: JcKeyFunction<JcAppLocation> = location => location;

export const JcAppDrawer = (props: JcAppDrawerProps): React.ReactElement => {
    const navigate = useNavigate();
    const commonProps = JcUtils.commonProps(props, [
        "jc-app-navigation-drawer",
        "jc-app-white-background"
    ])
    return <Drawer anchor="right"
        open={props.open}
        onClose={props.onClose}>
        <JcColumn {...commonProps}>
            <JcAppTitleText className="jc-app-drawer-title" />
            <JcSeparator />
            <JcList keyFunction={APP_DRAWER_KEY_FUNCTION}
                noBorder
                items={MAIN_LOCATIONS}
                selection={props.location}
                onSelection={location => navigate(getLocationRoute(location))}
                itemRenderer={location => getLocationLabel(location)} />
        </JcColumn>
    </Drawer>;
}